import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { stores, subject } from '@rewe/common';

import { useNotUndefined } from '../../common/hooks/use-not-undefined';
import { useUser } from '../../common/hooks/use-user';
import { StyledDataGrid } from '../../common/styles';
import { User } from '../../common/types';
import {
  SpaceBetweenRowContainer,
  StyledFormContainer,
} from '../../common/ui/containers';
import { AddUserButton } from './add-user';
import { DeleteUserButton } from './delete-user';
import { EditUserButton } from './edit-user';
import { SendInvitationButton } from './send-invitation';
import { useDataGridState } from './useDataGridState';
import { useFetchPageOfUsers } from './useFetchPageOfUsers';

export const ManageUserPage = () => {
  const { t } = useTranslation();
  const user = useUser();
  const { sort, field, pageNumber, pageSize, updateSearchParams } =
    useDataGridState();
  const { fetchedPage, isLoading } = useFetchPageOfUsers(
    pageNumber,
    pageSize,
    field,
    sort,
  );

  const rowCount = useNotUndefined(fetchedPage?.totalItems, 1);

  const columns: GridColDef<User>[] = [
    {
      field: 'firstName',
      headerName: t('page.add_user.table.first_name'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'lastName',
      headerName: t('page.add_user.table.last_name'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'role',
      headerName: t('page.add_user.table.role'),
      flex: 1,
      renderCell: (params) => t(`common.user_role.${params.value}`),
    },
    {
      field: 'store',
      headerName: t('page.add_user.table.store'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        stores.find((store) => store.id === params.value)?.name,
    },
    {
      field: 'managerId',
      headerName: t('page.add_user.table.manager'),
      flex: 1,
      minWidth: 100,
      align: 'center',
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon sx={{ color: 'success.main' }} />
        ) : (
          <ClearIcon sx={{ color: 'error.main' }} />
        );
      },
    },
    {
      field: 'acceptedInvitation',
      headerName: t('page.add_user.table.accepted_invitation'),
      flex: 1,
      minWidth: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            {params.value ? (
              <CheckIcon sx={{ color: 'success.main' }} />
            ) : (
              <ClearIcon sx={{ color: 'error.main' }} />
            )}
            {params.value === false && (
              <SendInvitationButton id={params.row.id} />
            )}
          </>
        );
      },
    },
    {
      field: 'Actions',
      headerName: t('page.add_user.table.actions'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <EditUserButton currentUser={params.row} />
            {user?.ability.can(
              'delete',
              subject('User', { id: params.id }),
            ) && (
              <DeleteUserButton
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                id={params.row.id}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <SpaceBetweenRowContainer>
        <Typography variant={'h1'}>{t('page.add_user.title')}</Typography>
        <AddUserButton />
      </SpaceBetweenRowContainer>
      <StyledFormContainer>
        <StyledDataGrid
          paginationMode={'server'}
          sortingMode={'server'}
          disableColumnFilter
          autoHeight
          pagination
          disableRowSelectionOnClick
          rows={fetchedPage?.items ?? []}
          loading={isLoading}
          rowCount={rowCount}
          initialState={{
            pagination: {
              paginationModel: { page: pageNumber, pageSize: pageSize },
            },
          }}
          paginationModel={{ page: pageNumber, pageSize }}
          pageSizeOptions={[]}
          onPaginationModelChange={({ page, pageSize }) => {
            updateSearchParams({
              page: page.toString(),
              pageSize: pageSize.toString(),
            });
          }}
          onSortModelChange={([sortModel]) => {
            updateSearchParams({
              sort: sortModel.sort ?? 'ASC',
              field: sortModel.field ?? 'firstName',
            });
          }}
          columns={columns}
        />
      </StyledFormContainer>
    </>
  );
};
