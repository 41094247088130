import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material';

import { Period } from '../../../common/types';
import { BarChart, ChartContainer } from '../../../common/ui/chart';
import { RoundedBorderContainer } from '../../../common/ui/containers';
import { StoreFilter } from '../../../common/ui/filter';
import { useCompanyMood } from '../hooks/useCompanyMood';
import { RankingTitle } from '../ranking/style';
import { convertCompanyMoodsToNovoChartData } from './convert-company-moods-to-novo-chart-data';

const RankingContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const TopRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CompanyMood = () => {
  const { t } = useTranslation();
  const [store, setStore] = React.useState<number | null>(null);

  const { data: companyMoods, isLoading } = useCompanyMood(
    Period.currentPeriod(),
    store,
  );

  return (
    <RoundedBorderContainer>
      <RankingContainer>
        <TopRow>
          <RankingTitle variant={'caption'} color={'gray'}>
            {t('page.home.ranking.company-mood.title')}
          </RankingTitle>
          <StoreFilter selectedStore={store} onFilterChange={setStore} />
        </TopRow>
        <ChartContainer>
          <BarChart
            data={convertCompanyMoodsToNovoChartData(companyMoods)}
            legendY={t('page.home.ranking.company-mood.chart.legendY')}
            legendX={t('page.home.ranking.company-mood.chart.legendX')}
            yMinValue={0}
            yMaxValue={3}
            isLoading={isLoading}
          />
        </ChartContainer>
      </RankingContainer>
    </RoundedBorderContainer>
  );
};
