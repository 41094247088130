import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';
import { useServices } from '../../providers/service-provider';
import { EvaluationFormResultScore } from '../../types';
import { useSnackbar } from '../../ui/snackbar';

export const useScores = (
  userId?: string, // authenticated user, when undefined
  year: number = dayjs().month() < 3 ? dayjs().year() - 1 : dayjs().year(),
) => {
  const { rankingService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data: currentYearScores, isLoading } = useQuery<
    EvaluationFormResultScore[],
    AppError
  >(
    ['year-scores', userId],
    () => {
      return rankingService.fetchYearScores(year, userId);
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );

  const totalScore = currentYearScores
    ?.map((evaluationFormResultScore) => evaluationFormResultScore.score)
    .reduce((partialSum, a) => partialSum + a, 0);

  return { currentYearScores, isLoading, totalScore, year };
};
