import { styled } from '@mui/material/styles';

export const SideMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 0,
  width: '100%',
  maxWidth: '350px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(4),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    maxWidth: 'none',
    width: '450px',
    height: '100%',
    backgroundColor: 'transparent',
  },
}));
