import { useMutation } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { AuthUser } from '../../../common/types';
import { LoginRequest } from '../../../services/auth';
import { AppError } from '../../../services/errors';

export const useLogin = (onSuccess: (user: AuthUser) => void) => {
  const { authService } = useServices();
  return useMutation<AuthUser, AppError, LoginRequest>(
    (loginRequest) => {
      return authService.login(loginRequest);
    },
    {
      onSuccess,
    },
  );
};
