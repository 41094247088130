import React from 'react';

import '@fontsource/rubik';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { InstallationProvider } from '../common/providers/installation-provider';
import { ServiceProvider } from '../common/providers/service-provider';
import { Snackbar, wrapSnackbarProvider } from '../common/ui/snackbar';
import { AppRouter } from '../routing';
import { AppUpdateDialog } from './app-update-dialog';
import './i18n';
import { services } from './services';
import { ThemeProvider } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const App = wrapSnackbarProvider(() => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ServiceProvider services={services}>
          <InstallationProvider>
            <CssBaseline />
            <AppRouter />
            <Snackbar />
            <AppUpdateDialog />
          </InstallationProvider>
        </ServiceProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
});
