import React from 'react';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { CircularProgress, Typography } from '@mui/material';

import { EvaluationFormViewMode, Period } from '../../common/types';
import { EvaluationFormView } from '../../common/ui/evaluation-form';
import { StyledDialog } from '../page-styles';
import { useFetchEvaluationForm } from './useFetchEvaluationForm';

interface Props {
  value: any;
  rowId: string;
  period: Period;
}

export const OpenEvaluationFormButton = React.memo(
  ({ value, rowId, period }: Props): JSX.Element => {
    const { isOpen, close, open } = useDialog<void, void>();

    const {
      mutate: fetchEvaluationForm,
      data: evaluationForm,
      isLoading,
    } = useFetchEvaluationForm(open);

    return (
      <>
        {isLoading ? (
          <CircularProgress
            size={15}
            sx={(theme) => ({ color: theme.palette.grey.A700 })}
          />
        ) : (
          <Typography
            sx={(theme) => ({
              cursor: 'pointer',
              color: theme.palette.primary.main,
              textDecoration: 'underline',
            })}
            onClick={() => {
              fetchEvaluationForm({
                userId: rowId,
                period: period,
              });
            }}
          >
            {value.toString()}
          </Typography>
        )}
        {!!evaluationForm && (
          <StyledDialog isOpen={isOpen} onClose={close}>
            <EvaluationFormView
              mode={EvaluationFormViewMode.readOnly}
              evaluationForm={evaluationForm}
              closeDialog={close}
            />
          </StyledDialog>
        )}
      </>
    );
  },
);
