import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';

export const useSendResetEmail = (onSuccess: () => void) => {
  const { authService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<void, AppError, string>(
    async (email) => {
      return authService.sendResetEmail(email);
    },
    {
      onSuccess: () => {
        showSnackbar({
          variant: 'success',
          content: t('page.reset.success_message'),
        });
        onSuccess();
      },
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
