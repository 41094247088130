import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export const buttonsStyling: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: () => ({
        ...{
          boxShadow: ['none'], // never show Shadow
          textTransform: 'none',
          textAlign: 'left',
          minWidth: '100px',
          '&:hover': {
            boxShadow: ['none'],
          },
        },
      }),
    },
  },
};
