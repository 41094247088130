import { styled } from '@mui/material/styles';

export const RoundedAuthContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    borderRadius: theme.shape.rounded,
    backgroundColor: theme.palette.background.paper,
    maxWidth: 600,
    boxShadow: '0px 20px 60px rgba(0, 0, 0, 0.05)',
  },
}));
