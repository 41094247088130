import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { Model, Serializer, StylesManager, Survey } from 'survey-react';
import 'survey-react/defaultV2.min.css';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import { Fab, IconButton } from '@mui/material';

import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';
import { EvaluationForm, EvaluationFormViewMode } from '../../types';
import { PrimaryButton } from '../buttons';
import { Dialog } from '../dialog';
import { convertAnswersToSurveyResult } from './converters';
import './evaluation-form.css';
import { useSaveEvaluationForm } from './useSaveEvaluationForm';

StylesManager.applyTheme('defaultV2');

interface DialogParams {
  title: string;
  definition: string;
}

interface Props {
  mode: EvaluationFormViewMode;
  closeDialog?: () => void;
  evaluationForm: EvaluationForm;
}

Serializer.addProperty('question', 'definition:text');

export const EvaluationFormView = ({
  mode,
  evaluationForm,
  closeDialog,
}: Props) => {
  const { isOpen, close, open } = useDialog<void, void>();
  const [definition, setDefinition] = useState<DialogParams | undefined>();
  const { t } = useTranslation();
  const saveEvaluationForm = useSaveEvaluationForm();

  const newModel = useMemo(() => {
    const model = new Model(evaluationForm.form);
    model.locale = 'de';
    model.showProgressBar = 'top';

    if (evaluationForm.answers) {
      model.data = convertAnswersToSurveyResult(evaluationForm.answers);
    }

    model.completedHtml = t('evaluation_form.complete_page_text');
    model.completeText = t('evaluation_form.complete_button');

    if (mode === EvaluationFormViewMode.readOnly) {
      model.mode = 'display';
      model.questionsOnPageMode = 'singlePage';
    }

    model.onComplete.add(async (sender: any, options: any) => {
      options.showSaveInProgress(
        t('evaluation_form.complete_page_text_while_saving'),
      );

      try {
        await saveEvaluationForm(sender.data, evaluationForm.id, mode);
        setTimeout(() => closeDialog && closeDialog(), 3000);
      } catch (error) {
        model.completedHtml = ' ';
        options.showSaveError(createErrorMessage(t, error as AppError));
      }
    });

    model.onUpdateQuestionCssClasses.add((survey: any, options: any) => {
      // custom css-class for secondary questions on page
      if (
        options.question.name === 'complete' ||
        options.question.name.endsWith('.reason')
      ) {
        options.cssClasses.title += ' small-title';
      }
    });

    model.onAfterRenderSurvey.add(() => {
      /*delete close button, because it might render multiple times*/
      document.getElementById('close-button-container')?.remove();
      const closeButtonContainer = document.createElement('div');
      closeButtonContainer.id = 'close-button-container';
      const progressbar = document.querySelector(
        '.sd-progress.sd-body__progress',
      );
      if (progressbar) {
        progressbar.appendChild(closeButtonContainer);
      }

      const closeIconButton = ReactDOM.createRoot(closeButtonContainer);
      closeIconButton.render(
        <IconButton
          onClick={() => {
            closeDialog && closeDialog();
          }}
        >
          <CloseIcon fontSize={'large'} />
        </IconButton>,
      );
    });

    /*info button*/
    let infoButtonContainer: Element | null = null;
    model.onAfterRenderQuestion.add(function (survey: any, options: any) {
      // set inputmode=none for dropdown input to prevent keyboard from opening
      const dropdownInput = document
        .getElementsByClassName('sd-dropdown__value')
        .item(0)
        ?.querySelector('input'); // select first child of type input

      if (dropdownInput) {
        dropdownInput.inputMode = 'none';
      }

      // Do nothing if a question contains no description to show in a modal popup
      if (!options.question.definition) return;

      /*delete info button, because it is rendering twice*/
      infoButtonContainer?.remove();

      /*create info button container*/
      infoButtonContainer = document.createElement('div');
      infoButtonContainer.className = 'info-button';

      /*Insert the created container*/
      const header = options.htmlElement.querySelector('h5');
      header.appendChild(infoButtonContainer);

      const infoButton = ReactDOM.createRoot(infoButtonContainer);
      infoButton.render(
        <Fab
          style={{ backgroundColor: '#CC071E', color: '#fff' }}
          size={'small'}
          aria-label="add"
          onClick={() => {
            setDefinition({
              title: options.question.title,
              definition: options.question.definition,
            });
            open();
          }}
        >
          <QuestionMarkSharpIcon />
        </Fab>,
      );
    });

    return model;
  }, [evaluationForm, closeDialog, saveEvaluationForm, open, t, mode]);

  return (
    <>
      {newModel && <Survey model={newModel} />}
      {/*Dialog shows more information for the question*/}
      <Dialog
        isOpen={isOpen}
        onClose={close}
        title={definition?.title}
        actions={() => {
          return (
            <PrimaryButton
              color={'secondary'}
              variant={'outlined'}
              onClick={() => close()}
            >
              {t('common.button.submit_button')}
            </PrimaryButton>
          );
        }}
      >
        {/*show html from server*/}
        <div
          dangerouslySetInnerHTML={{ __html: `${definition?.definition}` }}
        />
      </Dialog>
    </>
  );
};
