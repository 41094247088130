import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface Props {
  small?: boolean;
  color: string;
}
export const CircleIcon = ({ small, color, ...props }: Props) => {
  return (
    <FiberManualRecordIcon
      fontSize={small ? 'small' : 'medium'}
      sx={{
        color: color,
      }}
      {...props}
    />
  );
};
