import React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { RoundedContainer } from '../ui/containers';
import { Header } from './Header';
import { HeaderMobile } from './HeaderMobile';
import { PageContainer } from './PageContainer';
import { SideMenuContent } from './SideMenu';
import { SideMenuContainer } from './SideMenuContainer';

interface Props {
  children: React.ReactNode;
}

const LayoutContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(0, 4),
  },
}));

const StyledRoundedContainer = styled(RoundedContainer)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 4),
  },
}));

const Offset = styled('div')(() => ({
  height: '100px',
}));

export const Layout = ({ children }: Props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <LayoutContainer>
      {/*HEADER*/}
      <HeaderContainer>
        {isMdUp ? <Header /> : <HeaderMobile />}
      </HeaderContainer>
      {!isMdUp && <Offset />}
      <ContentContainer>
        {/*SIDE MENU*/}
        {isMdUp && (
          <SideMenuContainer>
            <StyledRoundedContainer>
              <SideMenuContent />
            </StyledRoundedContainer>
          </SideMenuContainer>
        )}
        {/*CONTENT PAGES*/}
        <RoundedContainer>
          <PageContainer>{children}</PageContainer>
        </RoundedContainer>
      </ContentContainer>
    </LayoutContainer>
  );
};
