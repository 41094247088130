import { styled } from '@mui/material/styles';

export const PageContainer = styled('div')(({ theme }) => ({
  boxShadow: 'none',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
}));
