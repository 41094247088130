import React from 'react';

import { CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ResponsiveBar } from '@nivo/bar';

import { ChartData } from './types';

interface Props {
  data: ChartData[];
  legendX: string;
  legendY: string;
  yMinValue?: 'auto' | number;
  yMaxValue?: 'auto' | number;
  isLoading: boolean;
}

const labelFormatter = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 1,
});

export const BarChart = ({
  data,
  legendX,
  legendY,
  yMinValue = 'auto',
  yMaxValue = 'auto',
  isLoading,
}: Props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <ResponsiveBar
          key={`isMdUp-${isMdUp}`} // renders the chart because of problems with media queries. The chart was always too big.
          keys={['yValue']}
          data={data as any} // any, because ChartData[] not possible
          minValue={yMinValue}
          maxValue={yMaxValue}
          indexBy={'xValue'}
          margin={
            isMdUp
              ? { top: 40, right: 0, bottom: 80, left: 50 }
              : { top: 50, right: 0, bottom: 50, left: 50 }
          }
          padding={0.4}
          groupMode="grouped"
          isInteractive={false}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={[theme.palette.primary.main]}
          labelTextColor={'#ffffff'}
          label={(d) => {
            return `${labelFormatter.format(d.value ?? 0)}`;
          }}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 3]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendX,
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          axisLeft={{
            format: (e) => (Math.floor(e) === e ? e : ''), //only show whole numbers
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendY,
            legendPosition: 'middle',
            legendOffset: -30,
          }}
          legends={[]}
        />
      )}
    </>
  );
};
