import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../common/providers/service-provider';
import { EvaluationFormResult } from '../../common/types';
import { useSnackbar } from '../../common/ui/snackbar';
import { AppError } from '../../services/errors';
import { createErrorMessage } from '../../utils/errors/create-error-message';

export const useFetchEvaluationFormResults = () => {
  const { evaluationFormResultService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useQuery<EvaluationFormResult[], AppError>(
    ['evaluation-form-results'],
    async () => {
      return evaluationFormResultService.fetchEvaluationFormResults();
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
