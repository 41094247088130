import { Period } from './period.ts';

export interface EvaluationFormSummary {
  id: number | null;
  status: EvaluationFormResultStatus;
  period: Period;
}

export enum EvaluationFormResultStatus {
  open = 'OPEN',
  waitingForReview = 'WAITING_FOR_REVIEW',
  completed = 'COMPLETED',
  empty = 'EMPTY',
  inReview = 'IN_REVIEW',
}

export type SurveyJS = any;

export type EvaluationForm = {
  answers: Answers | null;
  form: SurveyJS;
  id: number;
};

export type Answers = Record<string, Answer>;

export interface Answer {
  value: number;
  reason: string;
}

export interface EvaluationFormResult {
  name: string;
  status: EvaluationFormResultStatus;
  id: number;
}

export enum EvaluationFormViewMode {
  review = 'review',
  selfAssessment = 'selfAssessment',
  readOnly = 'readOnly',
}

export interface EvaluationFormResultScore {
  id: number;
  employeeId: string;
  score: number;
  period: Period;
}
