import { initializeApp } from 'firebase/app';

import { API_URL } from '../common/config';
import { Services } from '../common/providers/service-provider';
import { AuthService } from '../services/auth';
import { EvaluationFormResultService } from '../services/evaluation-form';
import { HttpClient } from '../services/http-client';
import { RankingService } from '../services/ranking';
import { ServiceWorkerService } from '../services/service-worker';
import { UserService } from '../services/user';
import devConfig from './firebase-config.development.json';
import prodConfig from './firebase-config.production.json';

const firebaseConfig =
  import.meta.env.MODE === 'production' ? prodConfig : devConfig;

initializeApp(firebaseConfig);

const httpClient = new HttpClient(API_URL);
const authService = new AuthService(httpClient);
const authorizedHttpClient = new HttpClient(
  API_URL!,
  async () => await authService.getToken(),
);
const evaluationFormResultService = new EvaluationFormResultService(
  authorizedHttpClient,
);
const userService = new UserService(authorizedHttpClient);
const serviceWorker = new ServiceWorkerService();
serviceWorker.init();
const rankingService = new RankingService(authorizedHttpClient);

export const services: Services = {
  authService,
  userService,
  rankingService,
  authorizedHttpClient,
  evaluationFormResultService,
  serviceWorker,
};
