import { styled } from '@mui/material';

import { Dialog } from '../../common/ui/dialog';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '.MuiPaper-root': {
      minWidth: '700px',
    },
  },
}));
