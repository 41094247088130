import React from 'react';

import { useServices } from '../../providers/service-provider';
import { AuthUser } from '../../types';

export function useUser() {
  const { authService } = useServices();

  const [user, setUser] = React.useState<AuthUser | null>(null);

  React.useEffect(() => {
    return authService.onUserChanged(setUser);
  }, [authService]);

  return user;
}
