import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, styled } from '@mui/material';

import { useUser } from '../../common/hooks/use-user';
import { useServices } from '../../common/providers/service-provider';
import { Page } from '../page-styles';
import { EvaluationFormCard } from './EvaluationFormCard';
import { CompanyMood } from './company-mood/CompanyMood';
import { PersonalStatistics } from './ranking';

const ContentContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(8),
  },
}));

export const HomePage = () => {
  const { t } = useTranslation();
  const { authService } = useServices();
  const user = useUser();

  return (
    <Page>
      {/*Show new EvaluationForm if available */}
      <ContentContainer>
        <Typography variant={'h1'}>
          {t('page.home.title_hello', { name: authService.user?.displayName })}
        </Typography>
        {user?.ability.can('read', 'EvaluationFormResult') && (
          <EvaluationFormCard />
        )}
      </ContentContainer>
      {/* Personal Ranking*/}
      <ContentContainer>
        <Typography variant={'h1'}>
          {t('page.home.title_total_ranking')}
        </Typography>
        <PersonalStatistics />
      </ContentContainer>
      {user?.ability.can('read', 'CompanyMood') && (
        <ContentContainer>
          <Typography variant={'h1'}>
            {t('page.home.ranking.title_company-mood')}
          </Typography>
          <CompanyMood />
        </ContentContainer>
      )}
    </Page>
  );
};
