import React from 'react';

import { BaseButton } from './BaseButton';

export const PrimaryButton = ({
  ...props
}: React.ComponentProps<typeof BaseButton>) => {
  return (
    <div>
      <BaseButton
        variant="contained"
        color={'primary'}
        disableElevation
        {...props}
      />
    </div>
  );
};
