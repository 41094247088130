import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { SWITCH_TO_QUARTERLY_MONTH } from '../../common/config.ts';
import { useNotUndefined } from '../../common/hooks/use-not-undefined';
import { StyledDataGrid } from '../../common/styles';
import { Period, RankingRow } from '../../common/types';
import {
  SpaceBetweenRowContainer,
  StyledFormContainer,
} from '../../common/ui/containers';
import { OpenEvaluationFormButton } from './OpenEvaluationFormButton';
import { ScoreDialogTypography } from './ScoreDialog';
import {
  convertFieldNameToPeriod,
  convertRankingPeriodToColumnName,
  convertRankingToRankingRow,
} from './converters/ranking-converters';
import { Filters } from './filter';
import { RankingsDataGridState } from './types';
import { useFetchPageOfRatings } from './useFetchPageOfRatings';

const DEFAULT_PAGE_SIZE = 20;

const initDataGridState: RankingsDataGridState = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: 'DESC',
  field: Period.currentPeriod()
    .previous(1, SWITCH_TO_QUARTERLY_MONTH)
    .toString(),
  periodType: 'QUARTER_OR_MONTH',
  store: null,
  question: null,
};

export const RankingsPage = () => {
  const { t } = useTranslation();

  const [dataGridState, setDataGridState] =
    useState<RankingsDataGridState>(initDataGridState);

  const { fetchedRatingsPage, isLoading } =
    useFetchPageOfRatings(dataGridState);

  const rowCount = useNotUndefined(fetchedRatingsPage?.totalItems, 0);

  const scoreColumns: GridColDef<RankingRow>[] =
    fetchedRatingsPage?.items[0]?.scores.map((column, index) => ({
      field: `score${index}`,
      align: 'center',
      headerAlign: 'center',
      headerName:
        fetchedRatingsPage &&
        convertRankingPeriodToColumnName(fetchedRatingsPage.items, index),
      flex: 1,
      renderCell: (params) => {
        if (params.value !== null) {
          const period = convertFieldNameToPeriod(
            params.field,
            fetchedRatingsPage.items,
          );
          return period instanceof Period ? (
            <OpenEvaluationFormButton
              rowId={params.row.id}
              value={params.value}
              period={period}
            />
          ) : (
            <ScoreDialogTypography
              value={params.value}
              year={
                convertFieldNameToPeriod(
                  params.field,
                  fetchedRatingsPage.items,
                ) as number
              }
              name={params.row.name}
              userId={params.row.id}
            />
          );
        } else {
          return '-';
        }
      },
    })) ?? [];

  const columns: GridColDef<RankingRow>[] = [
    {
      field: 'name',
      headerName: t('page.ranking.table.name'),
      flex: 1,
      minWidth: 150,
    },
    ...scoreColumns,
  ];

  return (
    <>
      <SpaceBetweenRowContainer>
        <Typography variant={'h1'}>{t('page.ranking.headline')}</Typography>
      </SpaceBetweenRowContainer>
      <Filters
        selectedPeriodType={dataGridState.periodType}
        selectedStore={dataGridState.store}
        selectedQuestion={dataGridState.question}
        onSelectedFilterChange={(update) => {
          setDataGridState((prev) => ({
            ...prev,
            [update.type]: update.value,
            ...(update.value === 'YEAR' && {
              field: Period.currentPeriod()
                .previous(1, SWITCH_TO_QUARTERLY_MONTH)
                .year.toString(),
            }),
            ...(update.value === 'QUARTER_OR_MONTH' && {
              field: Period.currentPeriod()
                .previous(1, SWITCH_TO_QUARTERLY_MONTH)
                .toString(),
            }),
          }));
        }}
      />

      <StyledFormContainer>
        <StyledDataGrid
          paginationMode={'server'}
          sortingMode={'server'}
          disableColumnFilter
          autoHeight
          pagination
          disableColumnMenu={true}
          disableRowSelectionOnClick
          rows={convertRankingToRankingRow(fetchedRatingsPage?.items ?? [])}
          loading={isLoading}
          rowCount={rowCount}
          paginationModel={{
            page: dataGridState.page,
            pageSize: dataGridState.pageSize,
          }}
          pageSizeOptions={[]}
          onPaginationModelChange={({ page, pageSize }) => {
            setDataGridState({
              ...dataGridState,
              page: page,
              pageSize: pageSize,
            });
          }}
          onSortModelChange={([sortModel]) => {
            if (fetchedRatingsPage !== undefined && sortModel !== undefined) {
              let field = sortModel.field;
              if (sortModel.field === 'name') {
                field = 'firstName';
              }

              if (sortModel.field.startsWith('score')) {
                field = convertFieldNameToPeriod(
                  sortModel.field,
                  fetchedRatingsPage.items,
                ).toString();
              }

              setDataGridState({
                ...dataGridState,
                sort: sortModel.sort ?? 'ASC',
                field: field,
              });
            }
          }}
          columns={columns}
        />
      </StyledFormContainer>
    </>
  );
};
