import React from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { HomeScreenSvg } from '../../resources/assets/svg';
import { isSafariMobile } from '../../utils/detect-browser';
import { CenteredContainer } from '../ui/containers';

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '100%',
  maxWidth: '400px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));
const Step = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
}));

interface Props {
  onOpen: boolean;
  onClose: () => void;
}

export const IOsAddToHomescreenInstructionModal = ({
  onOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={onOpen} onClose={onClose}>
      <ModalContent>
        {!isSafariMobile() ? (
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t('common.add_to_home_screen_modal.please_change_to_safari')}
          </Typography>
        ) : (
          <>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {t('common.add_to_home_screen_modal.title')}
            </Typography>
            <Step>
              <Typography variant={'caption'}>
                {t('common.add_to_home_screen_modal.first_step')}
              </Typography>
              <IosShareIcon
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                })}
                fontSize={'small'}
              />
            </Step>

            <Step>
              <Typography variant={'caption'}>
                {t('common.add_to_home_screen_modal.second_step')}
              </Typography>
              <HomeScreenSvg
                style={{ marginLeft: '10px' }}
                fill={'black'}
                height={'20px'}
              />
            </Step>
            <CenteredContainer>
              <ArrowDownwardIcon sx={{ mt: 3 }} />
            </CenteredContainer>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
