import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';

import { useServices } from '../../common/providers/service-provider';
import { EvaluationForm, Period } from '../../common/types';
import { useSnackbar } from '../../common/ui/snackbar';
import { AppError } from '../../services/errors';
import { createErrorMessage } from '../../utils/errors/create-error-message';

interface EvaluationFormResultRequest {
  userId: string;
  period: Period;
}

export const useFetchEvaluationForm = (onSuccess: () => void) => {
  const { evaluationFormResultService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<EvaluationForm, AppError, EvaluationFormResultRequest>(
    ['evaluation-form-result-id'],
    async ({ userId, period }) => {
      const { id } =
        await evaluationFormResultService.fetchEvaluationFormResult(
          userId,
          period.toString(),
        );

      return evaluationFormResultService.fetchEvaluationForm(id!);
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
