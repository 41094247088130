import React from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';

import { Confirm } from '../../../common/ui/confirm-dialog';
import { useDeleteUser } from './useDeleteUser';

interface Props {
  firstName: string;
  lastName: string;
  id: string;
}
export const DeleteUserButton = ({ firstName, lastName, id }: Props) => {
  const { t } = useTranslation();

  const { mutate: deleteUser, isLoading: isLoadingDeleteUser } =
    useDeleteUser();
  return (
    <Confirm
      isLoading={isLoadingDeleteUser}
      title={t('page.add_user.dialog_delete.title')}
      text={t('page.add_user.dialog_delete.description', {
        firstName: firstName,
        lastName: lastName,
      })}
      cancelButtonTitle={t('page.add_user.dialog_delete.cancel_button')}
      confirmButtonTitle={t('page.add_user.dialog_delete.submit_button')}
      onConfirm={() => deleteUser(id)}
      closeOnError={false}
    >
      {(onPress) => (
        <Tooltip title={t('page.add_user.dialog_delete.tooltip')}>
          <IconButton onClick={onPress}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Confirm>
  );
};
