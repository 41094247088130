import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../common/providers/service-provider';
import { Pagination, User } from '../../common/types';
import { useSnackbar } from '../../common/ui/snackbar';
import { AppError } from '../../services/errors';
import { createErrorMessage } from '../../utils/errors/create-error-message';

export const useFetchPageOfUsers = (
  page: number,
  pageSize: number,
  field: string,
  sort: string,
) => {
  const { userService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data: fetchedPage, isLoading } = useQuery<
    Pagination<User[]>,
    AppError
  >(
    ['users', page, pageSize, field, sort],
    () => {
      return userService.fetchUsersPerPage(page, pageSize, field, sort);
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );

  return { fetchedPage, isLoading } as const;
};
