import * as React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormResetField,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { UserRole, stores, subject } from '@rewe/common';

import { useUser } from '../../common/hooks/use-user';
import { StyledFormContainer } from '../../common/ui/containers';
import { ErrorMessage } from '../../common/ui/error-message';
import { AppError } from '../../services/errors';
import { regex } from '../../utils/regex';
import { useFetchManagersForSpecificUser } from './hook/useFetchManagersForSpecificUser';
import { UpsertUserFormValues } from './types';

interface Props {
  control: Control<UpsertUserFormValues, any>;
  formErrors: FieldErrors<UpsertUserFormValues>;
  addUserError: AppError | null;
  userId?: string;
  store: number;
  resetField: UseFormResetField<UpsertUserFormValues>;
}
export const UpsertUserForm = ({
  userId,
  control,
  formErrors,
  addUserError,
  store,
  resetField,
}: Props) => {
  const { t } = useTranslation();
  const authUser = useUser();

  const { data: managers } = useFetchManagersForSpecificUser(store, userId);

  return (
    <StyledFormContainer>
      <FormControl>
        <FormLabel>{t('page.add_user.form.store_label')}</FormLabel>

        <Controller
          control={control}
          name={'store'}
          render={({ field: { onChange, ...field } }) => (
            <RadioGroup
              row
              onChange={(e) => {
                resetField('managerId', { defaultValue: '' });
                onChange(Number(e.target.value));
              }}
              {...field}
            >
              {stores.map((store) => {
                return (
                  <FormControlLabel
                    key={store.name}
                    value={store.id}
                    control={<Radio />}
                    label={store.name}
                  />
                );
              })}
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('page.add_user.form.role_label')}</FormLabel>
        <Controller
          control={control}
          name={'role'}
          rules={{
            required: {
              value: true,
              message: t('common.validation.required'),
            },
          }}
          render={({ field }) => (
            <RadioGroup row {...field}>
              {Object.values(UserRole).map((role) => {
                return (
                  <FormControlLabel
                    disabled={authUser?.ability.cannot(
                      'update',
                      subject('User', { id: userId }),
                      'role',
                    )}
                    key={role}
                    value={role}
                    control={<Radio />}
                    label={t(`page.add_user.form.roles.${role}`)}
                  />
                );
              })}
            </RadioGroup>
          )}
        />
      </FormControl>

      <Controller
        control={control}
        name={'managerId'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref, ...field } }) => {
          return (
            <FormControl error={!!formErrors.managerId?.message}>
              <InputLabel>{t('page.add_user.form.manager_label')}</InputLabel>
              <Select
                ref={ref}
                label={t('page.add_user.form.manager_label')}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={!!formErrors.managerId}
                {...field}
              >
                {managers?.map((name) => (
                  <MenuItem key={name.name} value={name.id}>
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>
                {formErrors.managerId?.message}
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        control={control}
        name={'firstName'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'words'}
            type={'text'}
            label={t('page.add_user.form.first_name_label')}
            autoComplete={'name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={'lastName'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'words'}
            type={'text'}
            label={t('page.add_user.form.last_name_label')}
            autoComplete={'family-name'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        rules={{
          required: {
            value: true,
            message: t('common.validation.required'),
          },
          pattern: {
            value: regex.email,
            message: t('common.validation.email'),
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            ref={ref}
            autoCapitalize={'none'}
            type={'email'}
            label={t('page.add_user.form.email_label')}
            autoComplete={'email'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={!!formErrors.email}
            helperText={formErrors.email?.message}
          />
        )}
      />
      <ErrorMessage error={addUserError} />
    </StyledFormContainer>
  );
};
