import { FirebaseError } from 'firebase/app';
import {
  User as FirebaseAuthUser,
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

import { UserRole, defineAbilityFor } from '@rewe/common';

import { AuthUser } from '../../common/types';
import { AppError, ErrorType } from '../errors';
import { HttpClient } from '../http-client';
import {
  CheckInvitationCodeResponse,
  LoginRequest,
  RegisterRequest,
} from './types';

export class AuthService {
  private currentUser: AuthUser | null = null;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly auth = getAuth(),
  ) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.currentUser = await convertToAuthUser(user);
      } else {
        this.currentUser = null;
      }
    });
  }

  async sendResetEmail(email: string) {
    try {
      return await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        new AppError(ErrorType.UNKNOWN, (error as Error).message);
      }
    }
  }

  checkInvitationCode(
    invitationCode: string,
  ): Promise<CheckInvitationCodeResponse> {
    return this.httpClient.jsonRequest<CheckInvitationCodeResponse>(
      'GET',
      '/api/users/invitation',
      { code: invitationCode },
    );
  }

  async getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  get user() {
    return this.currentUser;
  }

  onUserChanged(onUserChange: (user: AuthUser | null) => void): Unsubscribe {
    return onAuthStateChanged(
      this.auth,
      async (user: FirebaseAuthUser | null) => {
        if (user) {
          const authUser = await convertToAuthUser(user);
          onUserChange(authUser);
        } else {
          onUserChange(null);
        }
      },
    );
  }

  async login(loginRequest: LoginRequest): Promise<AuthUser> {
    try {
      //firebase
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        loginRequest.email,
        loginRequest.password,
      );
      return convertToAuthUser(userCredential.user);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        throw error;
      }
    }
  }

  async register(request: RegisterRequest) {
    await this.httpClient.jsonRequest(
      'POST',
      '/api/users/register',
      undefined,
      request,
    );
  }

  async logout() {
    try {
      await signOut(this.auth);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw convertFirebaseErrorToAppError(error);
      } else {
        throw error;
      }
    }
  }
}

const convertToAuthUser = async (user: FirebaseAuthUser): Promise<AuthUser> => {
  const role = (await user.getIdTokenResult()).claims.role as UserRole;
  if (role === undefined) console.log('User has no Role');

  return {
    id: user.uid,
    email: user.email,
    displayName: user.displayName,
    role,
    ability: defineAbilityFor({
      id: user.uid,
      role: role ?? UserRole.employee,
    }),
  };
};

export const convertFirebaseErrorToAppError = (
  error: FirebaseError,
): AppError => {
  switch (error.code) {
    case 'auth/user-not-found':
      return new AppError(ErrorType.USER_NOT_FOUND);
    case 'auth/wrong-password':
      return new AppError(ErrorType.INVALID_CREDENTIALS);
    case 'auth/email-already-exists':
      return new AppError(ErrorType.DUPLICATE_EMAIL);
    case 'auth/network-request-failed':
      return new AppError(ErrorType.NETWORK);
    default:
      return new AppError(ErrorType.UNKNOWN);
  }
};
