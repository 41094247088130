import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { PrimaryButton } from '../buttons';

interface Props {
  onConfirm: () => Promise<void> | void;
  title: ReactNode;
  text: ReactNode;
  children: (onPress: () => void) => ReactNode;
  className?: string;
  isLoading?: boolean;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  closeOnError: boolean;
}

export const Confirm = ({
  children,
  title,
  className,
  text,
  onConfirm,
  isLoading,
  cancelButtonTitle,
  confirmButtonTitle,
  closeOnError,
}: Props) => {
  const { isOpen, open, close } = useDialog<void, void>();

  const { t } = useTranslation();

  return (
    <>
      {children(open)}

      <Dialog open={isOpen} onClose={() => close()} className={className}>
        <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main })}>
          {title}
        </DialogTitle>
        <DialogContent>
          <div>{text}</div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            color={'secondary'}
            variant={'outlined'}
            onClick={() => close()}
          >
            {cancelButtonTitle
              ? cancelButtonTitle
              : t('common.button.cancel_button')}
          </PrimaryButton>
          <PrimaryButton
            loading={isLoading}
            onClick={async () => {
              try {
                await onConfirm();
              } finally {
                if (closeOnError) close();
              }
            }}
          >
            {confirmButtonTitle
              ? confirmButtonTitle
              : t('common.button.submit_button')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
