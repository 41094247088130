import dayjs from 'dayjs';

import { Period as CommonPeriod } from '@rewe/common';

export class Period extends CommonPeriod {
  static currentPeriod(): Period {
    return Period.fromDate(new Date());
  }

  format(): string {
    if (typeof this.quarterOrMonth === 'number') {
      return dayjs().set('month', this.quarterOrMonth).format('MMM');
    } else {
      return `${this.quarterOrMonth.toString()}/${this.year.toString()}`;
    }
  }
}
