import * as React from 'react';

import { CloseOutlined } from '@mui/icons-material';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  useMediaQuery,
} from '@mui/material';
import MUIDialog from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

interface Props {
  children: JSX.Element;
  actions?: () => JSX.Element;
  contentPadding?: number;
  isOpen: boolean;
  transition?: React.ForwardRefExoticComponent<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    } & React.RefAttributes<unknown>
  >;
  onClose: () => void;
  title?: string;
  className?: string;
  closeButton?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

export const Dialog = ({
  children,
  actions,
  contentPadding,
  transition,
  isOpen,
  onClose,
  title,
  closeButton,
  ...props
}: Props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={transition ?? Transition}
      fullScreen={!isMdUp}
      {...props}
    >
      {closeButton && (
        <CloseButton onClick={onClose}>
          <CloseOutlined />
        </CloseButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      {title && <Divider />}
      <DialogContent
        sx={(theme) => ({
          padding: theme.spacing(contentPadding ?? 0),
          paddingTop: theme.spacing(0),
        })}
      >
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions()}</DialogActions>}
    </MUIDialog>
  );
};
