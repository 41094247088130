import React from 'react';
import { useTranslation } from 'react-i18next';

import { stores } from '@rewe/common';

import { SelectFilter } from './SelectFilter';

interface Props {
  selectedStore: number | null;
  onFilterChange: (storeId: number | null) => void;
}

export const StoreFilter = ({ selectedStore, onFilterChange }: Props) => {
  const { t } = useTranslation();

  return (
    <SelectFilter
      id={'store'}
      value={selectedStore?.toString() ?? null}
      label={t('page.ranking.filter_buttons.store')}
      onChange={(update) =>
        onFilterChange(update !== null ? Number(update) : null)
      }
      options={stores.map((store) => ({
        label: store.name,
        value: store.id.toString(),
      }))}
    />
  );
};
