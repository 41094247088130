import React from 'react';

import { styled } from '@mui/material/styles';

import { useScores } from '../../../common/hooks/use-scores';
import { Dialog } from '../../../common/ui/dialog';
import { ScoreChart } from '../../../common/ui/score-chart';

interface Props {
  name: string;
  year: number;
  userId: string;
  open: boolean;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '100%',
  },
}));

const StyledScoreChart = styled(ScoreChart)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const ScoreDialog = ({ open, onClose, year, userId, name }: Props) => {
  const { currentYearScores, isLoading } = useScores(userId, year);

  return (
    <StyledDialog isOpen={open} onClose={onClose} title={name} closeButton>
      <StyledScoreChart
        year={year}
        currentYearScores={currentYearScores ?? []}
        isLoading={isLoading}
      />
    </StyledDialog>
  );
};
