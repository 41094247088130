import { useSearchParams } from 'react-router-dom';

export type SearchParamsUpdate =
  | { page: string }
  | { pageSize: string }
  | { sort: string; field: string };

export const useDataGridState = () => {
  const init = { page: '0', pageSize: '50', sort: 'ASC', field: 'firstName' };
  const [searchParams, setSearchParams] = useSearchParams(init);
  const pageNumber = Number(searchParams.get('page'));
  const pageSize = Number(searchParams.get('pageSize'));

  const sort = searchParams.get('sort') ?? init.sort;
  const field = searchParams.get('field') ?? init.field;

  const updateSearchParams = (newSearchParams: SearchParamsUpdate) => {
    const paramsToAdd = new URLSearchParams(newSearchParams);
    const params = new URLSearchParams(searchParams);

    Array.from(paramsToAdd.entries()).forEach(([name, value]) => {
      params.set(name, value);
    });

    setSearchParams(params);
  };

  return {
    sort,
    field,
    pageNumber,
    pageSize,
    updateSearchParams,
  } as const;
};
