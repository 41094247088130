import { SWITCH_TO_QUARTERLY_MONTH } from '../../common/config.ts';
import {
  Answers,
  EvaluationForm,
  EvaluationFormResult,
  EvaluationFormResultStatus,
  EvaluationFormSummary,
  Period,
} from '../../common/types';
import { AppError, ErrorType } from '../errors';
import { HttpClient } from '../http-client';
import { convertToEvaluationFormResult } from './convert-to-evaluation-form-result';
import { EvaluationFormResultDto } from './types';

export class EvaluationFormResultService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  async saveEvaluationForm(
    id: number,
    answers: Answers,
  ): Promise<EvaluationFormSummary> {
    return this.authorizedHttpClient.jsonRequest(
      'POST',
      `/api/evaluation-form-results/${id}`,
      undefined,
      answers,
    );
  }

  async saveReviewedEvaluationForm(
    id: number,
    answers: Answers,
    complete: boolean,
  ): Promise<EvaluationFormSummary> {
    return this.authorizedHttpClient.jsonRequest(
      'PUT',
      `/api/evaluation-form-results/${id}`,
      { complete: String(complete) },
      answers,
    );
  }

  async fetchEvaluationFormResult(
    userId: string,
    period: string,
  ): Promise<EvaluationFormSummary> {
    return this.authorizedHttpClient.jsonRequest(
      'GET',
      '/api/evaluation-form-results/summary',
      {
        user: userId,
        period: period,
      },
    );
  }

  async fetchEvaluationFormResults(): Promise<EvaluationFormResult[]> {
    const response: EvaluationFormResultDto[] =
      await this.authorizedHttpClient.jsonRequest(
        'GET',
        '/api/evaluation-form-results',
        {
          period: Period.currentPeriod()
            .previous(1, SWITCH_TO_QUARTERLY_MONTH)
            .toString(),
        },
      );
    return convertToEvaluationFormResult(response);
  }

  async fetchCurrentEvaluationFormResultSummary(): Promise<EvaluationFormSummary> {
    const period = Period.currentPeriod().previous(
      1,
      SWITCH_TO_QUARTERLY_MONTH,
    );
    try {
      return await this.authorizedHttpClient.jsonRequest(
        'GET',
        `/api/evaluation-form-results/summary`,
        {
          period: period.toString(),
        },
      );
    } catch (error) {
      if (
        error instanceof AppError &&
        error.errorType === ErrorType.EVALUATION_FORM_RESULT_NOT_FOUND
      ) {
        return {
          id: null,
          status: EvaluationFormResultStatus.empty,
          period,
        };
      } else {
        throw error;
      }
    }
  }

  async fetchEvaluationForm(
    evaluationFormResultId: number,
  ): Promise<EvaluationForm> {
    const response: EvaluationForm =
      await this.authorizedHttpClient.jsonRequest(
        'GET',
        `/api/evaluation-form-results/${evaluationFormResultId}/form`,
      );
    return { ...response, id: evaluationFormResultId };
  }
}
