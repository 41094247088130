import React from 'react';
import { NavLink, To } from 'react-router-dom';

import { styled } from '@mui/material';

import { BaseButton } from './BaseButton';

interface MenuButtonProps {
  to?: To;
  bold?: boolean;
  startIcon: React.ReactElement;
  end?: boolean;
}

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(1.5),

  [`& .start-icon`]: {
    color: theme.palette.grey[600],
  },
}));

export const MenuButton = ({
  startIcon,
  bold = false,
  to,
  end,
  ...props
}: Omit<React.ComponentProps<typeof BaseButton>, 'startIcon'> &
  MenuButtonProps) => {
  const createStyledBaseButton = (isActive: boolean) => (
    <StyledBaseButton
      startIcon={React.cloneElement(startIcon, {
        className: !isActive ? 'start-icon' : undefined,
      })}
      variant={isActive ? 'contained' : 'text'}
      color={isActive ? 'primary' : 'secondary'}
      disableElevation={true}
      fullWidth
      bold={bold}
      pill
      {...props}
    />
  );

  return (
    <>
      {to ? (
        <NavLink to={to} end={end} style={{ textDecoration: 'none' }}>
          {({ isActive }) => createStyledBaseButton(isActive)}
        </NavLink>
      ) : (
        createStyledBaseButton(false)
      )}
    </>
  );
};
