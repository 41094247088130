import { styled } from '@mui/material/styles';

export const RoundedContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  boxShadow: 'none',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    borderRadius: theme.shape.rounded,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 20px 60px rgba(0, 0, 0, 0.05)',
  },
}));
