import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { Manager } from '../../../common/types/user';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';

export const useFetchManagersForSpecificUser = (
  store: number,
  userId?: string,
) => {
  const { userService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useQuery<Manager[], AppError>(
    ['managers', store],
    async () => {
      const managers = await userService.fetchManagers(store);
      // prevent self assigning
      return userId ? managers.filter((it) => it.id !== userId) : managers;
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
