import React from 'react';

export function useNotUndefined<T>(
  value: T | undefined,
  initialFallbackValue: T,
) {
  const [current, setCurrent] = React.useState(
    value === undefined ? initialFallbackValue : value,
  );
  React.useEffect(() => {
    setCurrent((previous) => (value !== undefined ? value : previous));
  }, [value, setCurrent]);

  return current;
}
