import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { styled } from '@mui/material';

import { BaseButton } from '../../../common/ui/buttons/BaseButton';
import AddUserDialog from './AddUserDialog';

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
}));

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const AddUserButton = ({
  ...props
}: React.ComponentProps<typeof BaseButton>) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();
  return (
    <Container>
      <StyledBaseButton
        startIcon={<AddCircleIcon />}
        size={'large'}
        onClick={() => open()}
        variant={'contained'}
        color={'primary'}
        disableElevation={true}
        pill
        {...props}
      >
        {t('page.add_user.add_button')}
      </StyledBaseButton>
      <AddUserDialog isDialogOpen={isOpen} onClose={close} />
    </Container>
  );
};
