import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CircleIcon } from './CircleIcon';

const Legend = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  marginRight: '10px',
}));
const LegendContainer = styled('div')(() => ({
  display: 'flex',
}));

export const AssignedEvaluationFormResultsDataGridLegend = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <LegendContainer>
      <Legend>
        <CircleIcon small color={theme.palette.success.main} />
        <Typography variant={'caption'}>
          {t(
            'page.assigned_evaluation_form_results_page.table.legend.completed',
          )}
        </Typography>
      </Legend>
      <Legend>
        <CircleIcon small color={theme.palette.warning.main} />

        <Typography variant={'caption'}>
          {t(
            'page.assigned_evaluation_form_results_page.table.legend.no_evaluation_form',
          )}
        </Typography>
      </Legend>
    </LegendContainer>
  );
};
