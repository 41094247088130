import {
  EvaluationFormResultScore,
  FilterOption,
  Pagination,
  Period,
  Ranking,
  RankingPeriod,
  Score,
} from '../../common/types';
import {
  CompanyMood,
  CompanyMoodDto,
  EvaluationFormResultScoreDto,
  PersonalRank,
} from '../../pages/home';
import { checkUnknownErrors } from '../../utils/errors/check-unknown-errors';
import { AppError, ErrorType } from '../errors';
import { HttpClient } from '../http-client';

export class RankingService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  async fetchFilterOptions(): Promise<FilterOption[]> {
    return checkUnknownErrors(async () => {
      return await this.authorizedHttpClient.jsonRequest(
        'GET',
        `/api/rankings/filter-options`,
      );
    });
  }

  async fetchPersonalRanking(period: Period): Promise<PersonalRank | null> {
    try {
      return await this.authorizedHttpClient.jsonRequest<PersonalRank>(
        'GET',
        '/api/rankings/personal-rank',
        { period: period.toString() },
      );
    } catch (error) {
      if (
        error instanceof AppError &&
        error.errorType === ErrorType.RANKING_NOT_AVAILABLE
      ) {
        return null;
      } else {
        throw error;
      }
    }
  }

  async fetchCompanyMood(
    period: Period,
    store: number | null,
  ): Promise<CompanyMood[]> {
    const response = await this.authorizedHttpClient.jsonRequest<
      CompanyMoodDto[]
    >('GET', '/api/evaluation-form-results/company-mood', {
      before: period.toString(),
      ...(store !== null && { store: store.toString() }),
    });

    return convertToCompanyMood(response);
  }

  async fetchYearScores(
    year: number,
    userId?: string,
  ): Promise<EvaluationFormResultScore[]> {
    const response = await this.authorizedHttpClient.jsonRequest<
      EvaluationFormResultScoreDto[]
    >('GET', '/api/evaluation-form-results/year-scores', {
      year: year.toString(),
      ...(userId !== undefined && { user: userId }),
    });
    return convertToEvaluationFormResultScore(response);
  }

  async fetchRatingsPerPage(
    page: number,
    pageSize: number,
    field: string,
    sort: string,
    period: RankingPeriod,
    store: number | null,
    questionName: string | null,
  ): Promise<Pagination<Ranking[]>> {
    const fixedQueryParams = {
      page: (page + 1).toString(),
      pageSize: pageSize.toString(),
      sort: field,
      order: sort.toString().toUpperCase(),
      period: period,
    };
    const response = await this.authorizedHttpClient.jsonRequest<
      Pagination<Ranking[]>
    >('GET', `/api/rankings`, {
      ...fixedQueryParams,
      ...(store !== null && { store: store.toString() }),
      ...(questionName !== null && { item: questionName }),
    });

    return {
      items: convertToRankings(response.items),
      totalItems: response.totalItems,
      totalPages: response.totalPages,
    };
  }
}

const convertToRankings = (rankingDtos: any[]): Ranking[] => {
  return rankingDtos.map((item) => ({
    period: item.period,
    scores: convertToScores(item.scores),
    user: item.user,
  }));
};
const convertToScores = (scoreDtos: any[]): Score[] => {
  return scoreDtos.map((score) => ({
    period: convertToPeriod(score.period),
    score: score.score,
  }));
};

const convertToPeriod = (periodDto: any): Period | number => {
  return isNaN(periodDto)
    ? new Period(periodDto.year, periodDto.quarterOrMonth)
    : periodDto;
};

const convertToEvaluationFormResultScore = (
  evaluationFormResultScores: EvaluationFormResultScoreDto[],
): EvaluationFormResultScore[] => {
  return evaluationFormResultScores.map((evaluationFormResultScore) => ({
    id: evaluationFormResultScore.id,
    employeeId: evaluationFormResultScore.employeeId,
    score: evaluationFormResultScore.score,
    period: new Period(
      evaluationFormResultScore.period.year,
      evaluationFormResultScore.period.quarterOrMonth,
    ),
  }));
};

const convertToCompanyMood = (
  companyMoods: CompanyMoodDto[],
): CompanyMood[] => {
  return companyMoods.map((companyMood) => ({
    averageMood: companyMood.averageMood,
    period: new Period(
      companyMood.period.year,
      companyMood.period.quarterOrMonth,
    ),
  }));
};
