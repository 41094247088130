import { uniq } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, styled } from '@mui/material';
import { Quarter } from '@rewe/common';

import { SWITCH_TO_QUARTERLY_MONTH } from '../../config.ts';
import { EvaluationFormResultScore, Period } from '../../types';
import { BarChart, ChartContainer, ChartData } from '../chart';
import { RoundedBorderContainer } from '../containers';

const Headline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 900,
}));

const Container = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

interface Props {
  year: number;
  currentYearScores: EvaluationFormResultScore[];
  isLoading: boolean;
  className?: string;
}

export const ScoreChart = ({
  year,
  currentYearScores,
  isLoading,
  className,
}: Props) => {
  const { t } = useTranslation();

  const convertEvaluationFormResultScoresOneYearToNovoChartData = (
    data: EvaluationFormResultScore[] | undefined,
  ): ChartData[] => {
    if (data !== undefined) {
      const oneYearArray =
        // special logic for switch from monthly to quarterly
        year === SWITCH_TO_QUARTERLY_MONTH.year
          ? uniq(
              Array.from(
                { length: 11 },
                (_, idx) =>
                  idx <= (SWITCH_TO_QUARTERLY_MONTH.quarterOrMonth as number)
                    ? idx
                    : Period.fromDate(new Date(`${year}-${idx + 1}-01`))
                        .quarterOrMonth, // will return Quarter
              ),
            )
          : Object.values(Quarter);

      return oneYearArray.map((period) => {
        const evaluationFormResultScore = data.find(
          (it: EvaluationFormResultScore) =>
            period === it.period.quarterOrMonth,
        );
        return {
          xValue: typeof period === 'number' ? String(period + 1) : period,
          yValue: evaluationFormResultScore?.score ?? 0, //0 is in color #ffffff not visible
        };
      });
    } else {
      return [];
    }
  };

  return (
    <RoundedBorderContainer className={className}>
      <Container>
        <Headline variant={'caption'} color={'gray'}>
          {t('common.score_chart.title', { year })}
        </Headline>
        {/*Diagram*/}
        <ChartContainer>
          <BarChart
            data={convertEvaluationFormResultScoresOneYearToNovoChartData(
              currentYearScores,
            )}
            legendY={t('common.score_chart.legend_y')}
            legendX={t('common.score_chart.legend_x')}
            isLoading={isLoading}
          />
        </ChartContainer>
      </Container>
    </RoundedBorderContainer>
  );
};
