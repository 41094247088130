import { Period, Ranking, RankingRow } from '../../../common/types';

export const convertRankingToRankingRow = (
  rankingArray: Ranking[],
): RankingRow[] => {
  return rankingArray.map((row) => ({
    id: row.user.id,
    name: row.user.firstName + ' ' + row.user.lastName,
    score0: row.scores[0].score,
    score1: row.scores[1].score,
    score2: row.scores[2].score,
    // we show scores 4 quarters, but only for 3 years
    ...(row.period === 'QUARTER_OR_MONTH'
      ? { score3: row.scores[3].score }
      : {}),
  }));
};

export const convertRankingPeriodToColumnName = (
  rankings: Ranking[],
  columnPosition: number,
): string => {
  const period = rankings[0].scores[columnPosition].period;

  if (typeof period === 'number') {
    /*YEAR 2022*/
    return period.toString();
  } else {
    /*YEARMONTH 2022-9*/
    return period.format();
  }
};

/**
 * converts RankingRow "score0" "score1" "score2" to ColumnName (2022-01,2022-02,...)
 */
export const convertFieldNameToPeriod = (
  fieldName: string,
  rankings: Ranking[],
): number | Period => {
  // get all fieldnames from first ranking row
  const fieldNames = rankings[0].scores.map((item) => {
    return item.period;
  });
  const scoreIndex = Number(fieldName.slice(-1));
  return fieldNames[scoreIndex];
};
