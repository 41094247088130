import { useState } from 'react';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

import LogoPng from '../../resources/assets/logo/logo.png';
import { SpaceBetweenRowContainer } from '../ui/containers';
import { SideMenuContent } from './SideMenu';
import { SideMenuContainer } from './SideMenuContainer';

const StyledDrawerLogo = styled('img')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  height: 90,
}));

const StyledMenuLogo = styled('img')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  height: 50,
}));

const DrawerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
}));

const CloseIconContainer = styled('div')(() => ({
  alignSelf: 'flex-end',
}));

const StyledSpaceBetweenRowContainer = styled(SpaceBetweenRowContainer)(
  ({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor: theme.palette.background.paper,
    zIndex: 100,
    boxShadow: '0 1px 20px -2px rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(0, 1),
  }),
);

export const HeaderMobile = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <StyledSpaceBetweenRowContainer>
        <IconButton color="primary" onClick={toggleDrawer}>
          <MenuIcon fontSize={'large'} />
        </IconButton>

        <StyledMenuLogo src={LogoPng} />
      </StyledSpaceBetweenRowContainer>
      <SwipeableDrawer open={open} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <DrawerContainer>
          <CloseIconContainer>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon fontSize={'large'} />
            </IconButton>
          </CloseIconContainer>
          <SideMenuContainer>
            <StyledDrawerLogo src={LogoPng} />
            <SideMenuContent closeDrawer={toggleDrawer} />
          </SideMenuContainer>
        </DrawerContainer>
      </SwipeableDrawer>
    </>
  );
};
