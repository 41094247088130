import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { Typography, styled } from '@mui/material';

import {
  EvaluationFormResultStatus,
  EvaluationFormSummary,
  EvaluationFormViewMode,
} from '../../common/types';
import { PrimaryButton } from '../../common/ui/buttons';
import { RoundedBorderContainer } from '../../common/ui/containers';
import { EvaluationFormView } from '../../common/ui/evaluation-form';
import {
  CheckingEvaluationFormSvg,
  NewEvaluationFormSvg,
  NoEvaluationFormSvg,
} from '../../resources/assets/svg';
import { StyledDialog } from '../page-styles';
import { useEvaluationForm, useEvaluationFormSummary } from './hooks';

const EvaluationFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  wordBreak: 'break-word',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const EvaluationFormCard = () => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();
  const { evaluationFormSummary } = useEvaluationFormSummary();
  const { data: evaluationForm, mutate: fetchEvaluationForm } =
    useEvaluationForm(open);

  const createEvaluationFormCard = (
    evaluationFormSummary: EvaluationFormSummary,
  ) => {
    switch (evaluationFormSummary.status) {
      case EvaluationFormResultStatus.open:
        //new evaluationform
        return (
          <>
            <div>
              <NewEvaluationFormSvg height={'100%'} width={'100%'} />
            </div>
            <ContentContainer>
              <Title variant={'h2'}>
                {t('page.home.elevation_form_box.open')}
              </Title>
              <PrimaryButton
                size={'small'}
                pill
                bold
                onClick={async () => {
                  if (evaluationFormSummary.id !== null) {
                    fetchEvaluationForm(evaluationFormSummary.id);
                  }
                }}
              >
                {t('page.home.elevation_form_box.open_button')}
              </PrimaryButton>
            </ContentContainer>
          </>
        );
      case EvaluationFormResultStatus.waitingForReview:
        //evaluationform will be checked
        return (
          <>
            <div>
              <CheckingEvaluationFormSvg height={'100%'} width={'100%'} />
            </div>
            <ContentContainer>
              <Typography variant={'h2'}>
                {t('page.home.elevation_form_box.completed')}
              </Typography>
            </ContentContainer>
          </>
        );
      default:
        //no evaluationform
        return (
          <>
            <div>
              <NoEvaluationFormSvg height={'100%'} width={'100%'} />
            </div>
            <ContentContainer>
              <Typography variant={'h2'}>
                {t('page.home.elevation_form_box.empty')}
              </Typography>
            </ContentContainer>
          </>
        );
    }
  };

  return (
    <RoundedBorderContainer>
      <EvaluationFormContainer>
        {evaluationFormSummary && (
          <>
            {createEvaluationFormCard(evaluationFormSummary)}
            {!!evaluationForm && (
              <StyledDialog isOpen={isOpen} onClose={close}>
                <EvaluationFormView
                  mode={EvaluationFormViewMode.selfAssessment}
                  evaluationForm={evaluationForm}
                  closeDialog={close}
                />
              </StyledDialog>
            )}
          </>
        )}
      </EvaluationFormContainer>
    </RoundedBorderContainer>
  );
};
