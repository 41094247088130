import React from 'react';

import { styled } from '@mui/material';

import { useUser } from '../../../common/hooks/use-user';
import { RankingPeriod } from '../../../common/types';
import { StoreFilter } from '../../../common/ui/filter';
import { PeriodFilter } from './PeriodFilter';
import { QuestionFilter } from './QuestionFilter';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  flexWrap: 'wrap',
}));

interface Props {
  selectedPeriodType: RankingPeriod;
  selectedStore: number | null;
  selectedQuestion: string | null;
  onSelectedFilterChange: (
    update:
      | { type: 'periodType'; value: RankingPeriod }
      | { type: 'store'; value: number | null }
      | { type: 'question'; value: string | null },
  ) => void;
}

export const Filters = ({
  onSelectedFilterChange,
  selectedPeriodType,
  selectedStore,
  selectedQuestion,
}: Props) => {
  const user = useUser();

  return (
    <>
      <Container>
        <PeriodFilter
          onFilterChange={(period) => {
            onSelectedFilterChange({
              type: 'periodType',
              value: period,
            });
          }}
          selectedPeriodType={selectedPeriodType}
        />
      </Container>

      {user?.ability.can('manage', 'all') && (
        <Container>
          <StoreFilter
            selectedStore={selectedStore}
            onFilterChange={(storeId) => {
              onSelectedFilterChange({
                type: 'store',
                value: storeId,
              });
            }}
          />
          <QuestionFilter
            selectedQuestion={selectedQuestion}
            onFilterChange={(questionName) => {
              onSelectedFilterChange({
                type: 'question',
                value: questionName,
              });
            }}
          />
        </Container>
      )}
    </>
  );
};
