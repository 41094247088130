import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';

import { AppError } from '../../../services/errors';

interface Props {
  error: AppError | null;
}

export const ErrorMessage = ({ error }: Props) => {
  const { t } = useTranslation();

  return error ? (
    <div>
      <Alert severity="error">{t(`common.errors.${error.errorType}`)}</Alert>
    </div>
  ) : null;
};
