import { styled } from '@mui/material';

export const ChartContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '200px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    height: '300px',
    width: '90.5%', // Hack, because the svg chart is not responsive while zooming in https://github.com/plouc/nivo/issues/411#issuecomment-761939245
  },
}));
