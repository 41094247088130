import { ErrorType } from '../../errors';

export const logUnknownErrorType = (
  errorTypeToCheck: string,
  errorTypeEnum: typeof ErrorType,
) => {
  if (!Object.keys(errorTypeEnum).includes(errorTypeToCheck)) {
    console.log(`New Error which is not in our ErrorTypes:`, errorTypeToCheck);
  }
};
