import React from 'react';

import { styled } from '@mui/material';

import { useScores } from '../../../common/hooks/use-scores';
import {
  RoundedBorderContainer,
  SpaceBetweenRowContainer,
} from '../../../common/ui/containers';
import { ScoreChart } from '../../../common/ui/score-chart';
import { PersonalRank } from './PersonalRank.tsx';
import { TotalRanking } from './TotalRanking';

const StyledSpaceBetweenRowContainer = styled(SpaceBetweenRowContainer)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      gap: theme.spacing(3),
    },
  }),
);

export const PersonalStatistics = () => {
  const {
    currentYearScores,
    isLoading: isLoadingChart,
    totalScore,
    year,
  } = useScores();

  return (
    <>
      <StyledSpaceBetweenRowContainer>
        <RoundedBorderContainer>
          <TotalRanking
            year={year}
            totalScore={totalScore ?? 0}
            isLoading={isLoadingChart}
          />
        </RoundedBorderContainer>
        <RoundedBorderContainer>
          <PersonalRank />
        </RoundedBorderContainer>
      </StyledSpaceBetweenRowContainer>

      <ScoreChart
        year={year}
        currentYearScores={currentYearScores ?? []}
        isLoading={isLoadingChart}
      />
    </>
  );
};
