import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { AppError } from '../../../services/errors';
import { AddUserRequest } from '../../../services/user';

export const useAddUser = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const { userService } = useServices();
  return useMutation<void, AppError, AddUserRequest>(
    async (userRequest) => {
      await userService.addUser(userRequest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        onSuccess();
      },
    },
  );
};
