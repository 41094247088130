import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { Period } from '../../../common/types';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';
import { PersonalRank } from '../types';

export const usePersonalRanking = (period: Period) => {
  const { rankingService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    data: personalRanking,
    isLoading,
    error,
  } = useQuery<PersonalRank | null, AppError>(
    ['personal-ranking'],
    () => {
      return rankingService.fetchPersonalRanking(period);
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
  return { personalRanking, isLoading, error };
};
