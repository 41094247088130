export var Quarter;
(function (Quarter) {
    Quarter["Q1"] = "Q1";
    Quarter["Q2"] = "Q2";
    Quarter["Q3"] = "Q3";
    Quarter["Q4"] = "Q4";
})(Quarter || (Quarter = {}));
function previous(quarter) {
    switch (quarter) {
        case Quarter.Q1:
            return Quarter.Q4;
        case Quarter.Q2:
            return Quarter.Q1;
        case Quarter.Q3:
            return Quarter.Q2;
        case Quarter.Q4:
            return Quarter.Q3;
    }
}
export function previousQuarter(quarter, delta) {
    for (let i = 0; i < delta; i++) {
        quarter = previous(quarter);
    }
    return quarter;
}
