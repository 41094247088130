import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { CheckInvitationCodeResponse } from '../../../services/auth';
import { AppError } from '../../../services/errors';

export function useCheckInvitationCode(
  invitationCode: string | null,
  onSuccess?: (email: CheckInvitationCodeResponse) => void,
) {
  const { authService } = useServices();

  return useQuery<CheckInvitationCodeResponse, AppError>(
    ['invitation-code', invitationCode],
    () => authService.checkInvitationCode(invitationCode!),
    {
      refetchOnWindowFocus: false,
      enabled: !!invitationCode,
      onSuccess,
    },
  );
}
