import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WestIcon from '@mui/icons-material/West';
import {
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import { PrimaryButton } from '../../../common/ui/buttons';
import {
  CenteredContainer,
  SpaceBetweenRowContainer,
  StyledFormContainer,
} from '../../../common/ui/containers';
import { ErrorMessage } from '../../../common/ui/error-message';
import { LOGIN } from '../../../routing/routes';
import { regex } from '../../../utils/regex';
import { CenteredPage } from '../../page-styles';
import { RoundedAuthContainer } from '../RoundedAuthContainer';
import { useSendResetEmail } from './useSendResetEmail';

const CenteredHeader = styled('div')(() => ({
  flexGrow: 1,
  textAlign: 'center',
}));

const StyledSpaceBetweenRowContainer = styled(SpaceBetweenRowContainer)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  }),
);
interface FormValues {
  email: string;
}

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });

  const {
    mutate: sendResetEmail,
    isLoading,
    error,
  } = useSendResetEmail(() => {
    navigate(LOGIN, { replace: true });
  });

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    sendResetEmail(formValues.email);
  };

  return (
    <CenteredPage>
      <RoundedAuthContainer>
        <StyledSpaceBetweenRowContainer>
          <IconButton color="secondary" onClick={() => navigate(-1)}>
            <WestIcon />
          </IconButton>

          <CenteredHeader>
            <Typography variant={'h3'} color={'primary'}>
              {t('page.reset.header_text')}
            </Typography>
          </CenteredHeader>
        </StyledSpaceBetweenRowContainer>

        <Divider />
        <CenteredContainer sx={(theme) => ({ margin: theme.spacing(3, 0) })}>
          <Typography>{t('page.reset.description')}</Typography>
        </CenteredContainer>
        <StyledFormContainer>
          <Controller
            control={control}
            name={'email'}
            rules={{
              required: {
                value: true,
                message: t('common.validation.required'),
              },
              pattern: {
                value: regex.email,
                message: t('common.validation.email'),
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                ref={ref}
                autoCapitalize={'none'}
                type={'email'}
                label={t('page.reset.email_label')}
                autoComplete={'email'}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />

          <ErrorMessage error={error} />

          <PrimaryButton
            loading={isLoading}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t('page.reset.submit_button')}
          </PrimaryButton>
        </StyledFormContainer>
      </RoundedAuthContainer>
    </CenteredPage>
  );
};
