import { registerSW } from 'virtual:pwa-register';

import {
  ServiceWorkerWrapper,
  ServiceWorkerWrapperImpl,
} from './service-worker-wrapper';

type Unregister = () => void;
type ServiceWorkerListener = (serviceWorker: ServiceWorkerWrapper) => void;

export class ServiceWorkerService {
  private listeners: ServiceWorkerListener[] = [];

  init() {
    const update = registerSW({
      onNeedRefresh: () => {
        console.log('new content available!');
        const wrapper = new ServiceWorkerWrapperImpl(update);
        this.listeners.map((listener) => listener(wrapper));
      },
      onRegisteredSW: (url, registration) => {
        console.log('registered service worker', registration);
      },
      onOfflineReady: () => {
        console.log('offline ready');
      },
      onRegisterError: (error: Error) => {
        console.error('service worker registration failed', error);
      },
    });
  }

  registerListener(listener: ServiceWorkerListener): Unregister {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((it) => it !== listener);
    };
  }
}
