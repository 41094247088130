import React from 'react';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { Typography } from '@mui/material';

import { ScoreDialog } from './ScoreDialog.tsx';

interface Props {
  name: string;
  year: number;
  userId: string;
  value: number;
}

export const ScoreDialogTypography = ({ value, year, userId, name }: Props) => {
  const { isOpen, close, open } = useDialog<void, void>();

  return (
    <>
      <Typography
        sx={(theme) => ({
          cursor: 'pointer',
          color: theme.palette.primary.main,
          textDecoration: 'underline',
        })}
        onClick={() => {
          open();
        }}
      >
        {value.toString()}
      </Typography>

      <ScoreDialog
        open={isOpen}
        onClose={close}
        year={year}
        userId={userId}
        name={name}
      />
    </>
  );
};
