import { AbilityBuilder, PureAbility, fieldPatternMatcher, mongoQueryMatcher, } from '@casl/ability';
import { UserRole } from './user-role';
export function defineAbilityFor(user) {
    const { can, cannot, build } = new AbilityBuilder(PureAbility);
    can(['read', 'create'], 'EvaluationFormResult', { employeeId: user.id });
    if ([UserRole.manager, UserRole.owner].includes(user.role)) {
        can(['read', 'update'], 'EvaluationFormResult', { managerId: user.id });
        can('read', 'Ranking');
    }
    if (user.role === UserRole.owner) {
        can('manage', 'all');
        cannot('delete', 'User', { id: user.id });
        cannot('update', 'User', ['role'], { id: user.id });
    }
    return build({
        conditionsMatcher: mongoQueryMatcher,
        fieldMatcher: fieldPatternMatcher,
    });
}
