import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../common/providers/service-provider';
import { Pagination, Ranking } from '../../common/types';
import { useSnackbar } from '../../common/ui/snackbar';
import { AppError } from '../../services/errors';
import { createErrorMessage } from '../../utils/errors/create-error-message';
import { RankingsDataGridState } from './types';

export const useFetchPageOfRatings = ({
  page,
  pageSize,
  field,
  sort,
  periodType,
  store,
  question,
}: RankingsDataGridState) => {
  const { rankingService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { data: fetchedRatingsPage, isLoading } = useQuery<
    Pagination<Ranking[]>,
    AppError
  >(
    ['ranking', page, pageSize, field, sort, periodType, store, question],
    async () => {
      return rankingService.fetchRatingsPerPage(
        page,
        pageSize,
        field,
        sort,
        periodType,
        store,
        question,
      );
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
  return { fetchedRatingsPage, isLoading } as const;
};
