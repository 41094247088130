declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    background?: Partial<TypeBackground>;
  }

  interface TypeBackground {
    gradient?: string;
  }
}

export const colors = {
  primary: {
    main: '#CC071E',
    light: '#ff5147',
    dark: '#930000',
    contrastText: '#fff',
  },
  secondary: {
    main: '#252326',
    light: '#4d4b4e',
    dark: '#000000',
    contrastText: '#fff',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    gradient: 'linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 100%)',
  },
};
