import React from 'react';

import { deDE } from '@mui/material/locale';
import { ThemeProvider as Provider, createTheme } from '@mui/material/styles';

import { buttonsStyling } from './button-styles';
import { colors } from './colors';
import { typography } from './typography';

interface Props {
  children: React.ReactNode;
}

//add Shape
declare module '@mui/system/createTheme/shape' {
  interface Shape {
    rounded: number;
  }
}

const theme = createTheme(
  {
    components: {
      ...buttonsStyling,
      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }) => ({
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(6),
              borderRadius: theme.shape.rounded,
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 20px 60px rgba(0, 0, 0, 0.05)',
            },
          }),
          paperFullScreen: {
            borderRadius: 0,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            [theme.breakpoints.up('md')]: {
              fontSize: 30,
            },
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            background: theme.palette.background.default,
            [theme.breakpoints.up('md')]: {
              background: theme.palette.background.gradient,
            },
          },
        }),
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
    },
    typography: { ...typography },
    spacing: 8,
    palette: colors,
    shape: { borderRadius: 2, rounded: 24 },
  },
  deDE,
);

export const ThemeProvider = ({ children }: Props) => {
  return <Provider theme={theme}>{children}</Provider>;
};
