import { useMutation } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { RegisterRequest } from '../../../services/auth';
import { AppError } from '../../../services/errors';

export function useRegister(onSuccess?: () => void) {
  const { authService } = useServices();

  return useMutation<void, AppError, RegisterRequest>(
    async (registerRequest) => {
      await authService.register(registerRequest);
      await authService.login({
        email: registerRequest.email,
        password: registerRequest.password,
      });
    },
    {
      onSuccess,
    },
  );
}
