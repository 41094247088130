import React from 'react';

import { Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import LogoPng from '../../resources/assets/logo/logo.png';
import { useServices } from '../providers/service-provider';
import { RoundedContainer, SpaceBetweenRowContainer } from '../ui/containers';

const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const StyledLogo = styled('img')(() => ({
  height: '60px',
}));
export const Header = () => {
  const theme = useTheme();
  const { authService } = useServices();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {isMdUp && (
        <RoundedContainer
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            alignItems: 'center',
            padding: theme.spacing(3),
            marginTop: theme.spacing(4),
          }}
        >
          <SpaceBetweenRowContainer>
            <StyledLogo src={LogoPng} />
            <StyledTypography variant={'body2'}>
              {authService.user?.displayName}
            </StyledTypography>
          </SpaceBetweenRowContainer>
        </RoundedContainer>
      )}
    </>
  );
};
