import { Pagination, User } from '../../common/types';
import { Manager } from '../../common/types/user';
import { HttpClient } from '../http-client';
import { AddUserRequest } from './types';

export class UserService {
  constructor(private readonly authorizedHttpClient: HttpClient) {}

  async fetchManagers(store: number): Promise<Manager[]> {
    return await this.authorizedHttpClient.jsonRequest(
      'GET',
      `/api/users/managers`,
      { store: store.toString() },
    );
  }
  async addUser(userRequest: AddUserRequest) {
    await this.authorizedHttpClient.jsonRequest(
      'POST',
      '/api/users',
      undefined,
      userRequest,
    );
  }

  async editUser(userRequest: AddUserRequest, id: string) {
    await this.authorizedHttpClient.jsonRequest(
      'PUT',
      `/api/users/${id}`,
      undefined,
      userRequest,
    );
  }

  async inviteUser(id: string) {
    await this.authorizedHttpClient.jsonRequest(
      'POST',
      `/api/users/${id}/invitation`,
      undefined,
    );
  }

  async deleteUser(id: string) {
    await this.authorizedHttpClient.jsonRequest('DELETE', `/api/users/${id}`);
  }

  async fetchUsersPerPage(
    page: number,
    pageSize: number,
    field: string,
    sort: string,
  ): Promise<Pagination<User[]>> {
    return this.authorizedHttpClient.jsonRequest('GET', `/api/users`, {
      page: (page + 1).toString(),
      pageSize: pageSize.toString(),
      sort: field,
      order: sort.toUpperCase(),
    });
  }
}
