export enum ErrorType {
  UNKNOWN = 'UNKNOWN',
  NETWORK = 'NETWORK',
  DEVELOPER = 'DEVELOPER',

  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',

  INVALID_TOKEN = 'INVALID_TOKEN',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_REQUEST = 'INVALID_REQUEST',

  // User Management
  CREATE_USER_FAILED = 'CREATE_USER_FAILED',
  UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
  FAILED_TO_DELETE = 'FAILED_TO_DELETE',
  USER_EXISTS = 'USER_EXISTS',

  // Auth
  INVITATION_CODE_ALREADY_REDEEMED = 'INVITATION_CODE_ALREADY_REDEEMED',
  INVITATION_CODE_NOT_FOUND = 'INVITATION_CODE_NOT_FOUND',

  //EvaluationForm
  EVALUATION_FORM_RESULT_NOT_FOUND = 'EVALUATION_FORM_RESULT_NOT_FOUND',
  EVALUATION_FORM_ALREADY_FILLED = 'EVALUATION_FORM_ALREADY_FILLED',
  EVALUATION_FORM_VALIDATION_ERROR = 'EVALUATION_FORM_VALIDATION_ERROR',

  //Ranking
  RANKING_NOT_AVAILABLE = 'RANKING_NOT_AVAILABLE',
}
