import { EvaluationFormResult } from '../../common/types';
import { EvaluationFormResultDto } from './types';

export const convertToEvaluationFormResult = (
  evaluationFormResults: EvaluationFormResultDto[],
): EvaluationFormResult[] => {
  return evaluationFormResults.map((evaluationFormResult) => ({
    name:
      evaluationFormResult.employee.firstName +
      ' ' +
      evaluationFormResult.employee.lastName,
    status: evaluationFormResult.status,
    id: evaluationFormResult.id,
  }));
};
