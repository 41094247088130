import { styled } from '@mui/material/styles';

export const Page = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
}));
