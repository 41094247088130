import dayjs from 'dayjs';
import { Quarter, previousQuarter } from './quarter';
export class Period {
    constructor(year, quarterOrMonth) {
        this.year = year;
        this.quarterOrMonth = quarterOrMonth;
    }
    static fromString(str) {
        const [year, quarterOrMonth] = str.split('-');
        if (quarterOrMonth.startsWith('Q')) {
            return new this(parseInt(year, 10), quarterOrMonth);
        }
        else {
            return new this(parseInt(year, 10), parseInt(quarterOrMonth, 10));
        }
    }
    static fromDate(date) {
        const dayjsDate = dayjs(date);
        if (dayjsDate.month() === 11) {
            return new this(dayjsDate.year() + 1, Quarter.Q1);
        }
        else {
            if (dayjsDate.month() < 3) {
                return new this(dayjsDate.year(), Quarter.Q1);
            }
            else if (dayjsDate.month() < 6) {
                return new this(dayjsDate.year(), Quarter.Q2);
            }
            else if (dayjsDate.month() < 9) {
                return new this(dayjsDate.year(), Quarter.Q3);
            }
            else {
                return new this(dayjsDate.year(), Quarter.Q4);
            }
        }
    }
    previous(delta = 1, switchToQuarterlyYearMonth) {
        if (delta === 0) {
            return this;
        }
        let newPeriod;
        const firstQuarterPeriod = Period.fromDate(dayjs()
            .year(switchToQuarterlyYearMonth.year)
            .month(switchToQuarterlyYearMonth.quarterOrMonth)
            .add(1, 'months')
            .toDate());
        if (typeof this.quarterOrMonth === 'number') {
            const oneMonthEarlier = dayjs(new Date(this.year, this.quarterOrMonth)).subtract(1, 'months');
            newPeriod = new this.constructor(oneMonthEarlier.year(), oneMonthEarlier.month());
        }
        else if (this.equals(firstQuarterPeriod)) {
            newPeriod = new this.constructor(switchToQuarterlyYearMonth.year, switchToQuarterlyYearMonth.quarterOrMonth);
        }
        else {
            const newYear = this.quarterOrMonth === Quarter.Q1 ? this.year - 1 : this.year;
            newPeriod = new this.constructor(newYear, previousQuarter(this.quarterOrMonth, 1));
        }
        return newPeriod.previous(delta - 1, switchToQuarterlyYearMonth);
    }
    toString() {
        return `${this.year}-${typeof this.quarterOrMonth === 'number'
            ? this.quarterOrMonth.toString(10).padStart(2, '0')
            : this.quarterOrMonth}`;
    }
    equals(other) {
        return (this.quarterOrMonth === other.quarterOrMonth && this.year === other.year);
    }
}
