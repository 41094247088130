import {
  HttpClient as Http,
  isoDateReviver,
} from '@lumind-solutions/http-client';

import { AppError, ErrorType } from '../errors';
import { logUnknownErrorType } from './log-unknown-error-type';

export class HttpClient extends Http {
  constructor(
    baseUrl: string,
    private readonly getToken?: () => Promise<string | undefined>,
  ) {
    super({ dateReviver: isoDateReviver, baseUrl });
  }

  protected onNetworkFailure() {
    throw new AppError(ErrorType.NETWORK, 'Failed to make fetch request');
  }

  protected async onRequestFailure(response: Response): Promise<void> {
    let json;
    try {
      json = await response.json();
    } catch (e) {
      throw new AppError(ErrorType.UNKNOWN);
    }

    if ('errorType' in json) {
      logUnknownErrorType(json.errorType, ErrorType);
      throw new AppError(json.errorType, json.message);
    } else {
      switch (response.status) {
        case 401:
          throw new AppError(ErrorType.UNAUTHENTICATED, 'Unauthenticated');
        case 403:
          throw new AppError(ErrorType.FORBIDDEN, 'Forbidden');
        case 404:
          throw new AppError(ErrorType.NOT_FOUND, 'Not found');
        default:
          throw new AppError(
            ErrorType.UNKNOWN,
            `Failed with statusCode(${json.statusCode})`,
          );
      }
    }
  }

  protected onResponseHandlerFailure(e: Error) {
    throw new AppError(ErrorType.UNKNOWN, e.message);
  }

  protected async authorizeRequest(): Promise<Record<string, string>> {
    if (this.getToken) {
      const token = await this.getToken();
      if (token) {
        return { Authorization: `Bearer ${token}` };
      }
    }
    return super.authorizeRequest();
  }
}
