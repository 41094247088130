import { Typography, styled } from '@mui/material';

export const RankingTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 900,
}));

export const RankingTypography = styled(Typography)(() => ({
  fontWeight: 900,
}));

export const RankingContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
