export const breakpoint = 900;
export const typography = {
  fontSize: 15,
  fontFamily: [
    'Rubik',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginLeft: '8px',
    marginBottom: '16px',
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: '1.8rem',
    },
  },
  h2: {
    fontSize: '1.1rem',
    fontWeight: 500,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: '1.5rem',
    },
  },
  h3: {
    fontSize: '1rem',
    fontWeight: 900,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: '1.3rem',
    },
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 300,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: '1.2rem',
    },
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 700,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: '1.2rem',
    },
  },
};
