import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserRole, stores } from '@rewe/common';

import { PrimaryButton } from '../../../common/ui/buttons';
import { Dialog } from '../../../common/ui/dialog';
import { UpsertUserForm } from '../UpsertUserForm';
import { UpsertUserFormValues } from '../types';
import { useAddUser } from './useAddUser';

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
}

export default function AddUserDialog({ isDialogOpen, onClose }: Props) {
  const { t } = useTranslation();

  const {
    mutate: addUser,
    error: addUserError,
    isLoading: isLoadingAddUser,
  } = useAddUser(onClose);

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    watch,
    formState: { errors: formErrors },
  } = useForm<UpsertUserFormValues>({
    defaultValues: {
      store: stores[0].id,
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.employee,
      managerId: '',
    },
  });

  const onSubmit: SubmitHandler<UpsertUserFormValues> = (data) => {
    addUser(data);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={handleOnClose}
      title={t('page.add_user.dialog_add.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <PrimaryButton
              variant="outlined"
              color={'secondary'}
              onClick={handleOnClose}
            >
              {t('page.add_user.dialog_add.cancel_button')}
            </PrimaryButton>
            <PrimaryButton
              loading={isLoadingAddUser}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.add_user.dialog_add.submit_button')}
            </PrimaryButton>
          </>
        );
      }}
    >
      <UpsertUserForm
        control={control}
        formErrors={formErrors}
        addUserError={addUserError}
        store={watch('store', stores[0].id)}
        resetField={resetField}
      />
    </Dialog>
  );
}
