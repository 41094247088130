import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AddToHomeScreenOutlinedIcon from '@mui/icons-material/AddToHomeScreenOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Divider, Typography, styled } from '@mui/material';

import PackageJson from '../../../package.json';
import {
  ASSIGNED_EVALUATION_FORM_RESULTS,
  HOME,
  MANAGE_USER,
  RANKING,
} from '../../routing/routes';
import { isAppleDeviceAndAppNotInstalled } from '../../utils/detect-browser';
import { useLogout } from '../hooks/use-logout/useLogout';
import { useInstallationPromptEvent } from '../providers/installation-provider';
import { useServices } from '../providers/service-provider';
import { MenuButton } from '../ui/buttons';
import { IOsAddToHomescreenInstructionModal } from './IOsAddToHomescreenInstructionModal';

const SpaceBetweenColumnContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const VersionNumber = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

interface Props {
  closeDrawer?: () => void;
}

export const SideMenuContent = ({ closeDrawer }: Props) => {
  const { t } = useTranslation();
  const { authService } = useServices();
  const { mutate: logout, isLoading } = useLogout();
  const pwaInstallEvent = useInstallationPromptEvent();

  const [
    isIOsAddToHomescreenInstructionModalOpen,
    setIsIOsAddToHomescreenInstructionModalOpen,
  ] = useState<boolean>(false);

  return (
    <SpaceBetweenColumnContainer>
      <div>
        <MenuButton
          startIcon={<TaskOutlinedIcon />}
          to={HOME}
          end
          onClick={closeDrawer}
        >
          {t('common.menu.current_evaluationform')}
        </MenuButton>
        {authService.user?.ability.can('update', 'EvaluationFormResult') && (
          <MenuButton
            startIcon={<AutoAwesomeMotionOutlinedIcon />}
            to={ASSIGNED_EVALUATION_FORM_RESULTS}
            onClick={closeDrawer}
          >
            {t('common.menu.evaluation_form_results')}
          </MenuButton>
        )}
        {authService.user?.ability.can('read', 'Ranking') && (
          <MenuButton
            startIcon={<QueryStatsOutlinedIcon />}
            to={RANKING}
            onClick={closeDrawer}
          >
            {t('common.menu.ranking')}
          </MenuButton>
        )}
        {authService.user?.ability.can('manage', 'User') && (
          <MenuButton
            startIcon={<PersonAddAltOutlinedIcon />}
            to={MANAGE_USER}
            onClick={closeDrawer}
          >
            {t('common.menu.manage_employees')}
          </MenuButton>
        )}
        <StyledDivider />
        {/*show if InstallationEvent exists or if its on safari mobile*/}
        {(pwaInstallEvent !== null || isAppleDeviceAndAppNotInstalled()) && (
          <MenuButton
            startIcon={<AddToHomeScreenOutlinedIcon />}
            onClick={() => {
              pwaInstallEvent
                ? pwaInstallEvent.prompt()
                : setIsIOsAddToHomescreenInstructionModalOpen(true);
            }}
          >
            {t('common.menu.add_to_home_screen')}
          </MenuButton>
        )}
      </div>
      <div>
        <MenuButton
          startIcon={<LogoutOutlinedIcon />}
          loading={isLoading}
          onClick={() => {
            closeDrawer && closeDrawer();
            logout();
          }}
          bold
        >
          {t('common.menu.logout')}
        </MenuButton>
        <VersionNumber
          variant={'caption'}
          color={'lightgray'}
        >{`v${PackageJson.version}`}</VersionNumber>
      </div>
      <IOsAddToHomescreenInstructionModal
        onOpen={isIOsAddToHomescreenInstructionModalOpen}
        onClose={() => {
          setIsIOsAddToHomescreenInstructionModalOpen(false);
          closeDrawer && closeDrawer();
        }}
      />
    </SpaceBetweenColumnContainer>
  );
};
