import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import { SpaceBetweenRowContainer } from '../../../common/ui/containers';
import { RankingContainer, RankingTitle, RankingTypography } from './style';

interface Props {
  totalScore: number;
  isLoading: boolean;
  year: number;
}

export const TotalRanking = ({ totalScore, year, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <RankingContainer>
      <RankingTitle variant={'caption'} color={'gray'}>
        {t('page.home.ranking.total')}
      </RankingTitle>
      <SpaceBetweenRowContainer>
        <RankingTypography sx={{ margin: 0 }} variant={'h1'}>
          {isLoading ? <CircularProgress size={10} /> : totalScore}
        </RankingTypography>
        <RankingTypography variant={'caption'} color={'gray'}>
          {year.toString()}
        </RankingTypography>
      </SpaceBetweenRowContainer>
    </RankingContainer>
  );
};
