import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { Period } from '../../../common/types';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';
import { CompanyMood } from '../types';

export const useCompanyMood = (period: Period, store: number | null) => {
  const { rankingService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useQuery<CompanyMood[], AppError>(
    ['company-mood', store],
    () => {
      return rankingService.fetchCompanyMood(period, store);
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
