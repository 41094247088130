import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useServices } from '../../providers/service-provider';
import { EvaluationFormSummary, EvaluationFormViewMode } from '../../types';
import { convertSurveyResultToAnswers } from './converters';

export const useSaveEvaluationForm = () => {
  const queryClient = useQueryClient();
  const { evaluationFormResultService } = useServices();

  return useCallback(
    async (
      senderData: any,
      evaluationFormResultId: number,
      mode: EvaluationFormViewMode,
    ) => {
      const { complete, ...data } = senderData;
      let evaluationFormSummary: EvaluationFormSummary | undefined;
      if (mode === EvaluationFormViewMode.selfAssessment) {
        evaluationFormSummary =
          await evaluationFormResultService.saveEvaluationForm(
            evaluationFormResultId,
            convertSurveyResultToAnswers(data),
          );
      } else {
        evaluationFormSummary =
          await evaluationFormResultService.saveReviewedEvaluationForm(
            evaluationFormResultId,
            convertSurveyResultToAnswers(data),
            complete?.length > 0,
          );
      }

      await queryClient.invalidateQueries(['evaluation-form-results']);
      queryClient.setQueryData(
        ['evaluation-form-result-summary'],
        evaluationFormSummary,
      );
    },
    [queryClient, evaluationFormResultService],
  );
};
