import React, { useEffect, useState } from 'react';

import { Snackbar as MUISnackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useSnackbar } from './SnackbarProvider';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface Props {
  className?: string;
}

export const Snackbar = ({ className }: Props) => {
  const { snackbarContent, hideSnackbar } = useSnackbar();
  const [variant, setVariant] = useState(snackbarContent?.variant);

  // workaround: seeing green background while Alert close
  useEffect(() => {
    if (snackbarContent !== null) {
      setVariant(snackbarContent?.variant);
    }
  }, [snackbarContent]);

  return (
    <MUISnackbar
      open={!!snackbarContent}
      onClose={hideSnackbar}
      autoHideDuration={snackbarContent?.duration ?? 3000}
      className={className}
    >
      <Alert onClose={hideSnackbar} severity={variant} sx={{ width: '100%' }}>
        {snackbarContent?.content}
      </Alert>
    </MUISnackbar>
  );
};
