import { Answers } from '../../../../types';

export const convertAnswersToSurveyResult = (
  answers: Answers,
): Record<string, number | null> => {
  return Object.fromEntries(
    Object.entries(answers).flatMap(([outerKey, answer]) =>
      Object.entries(answer).map(([innerKey, value]) => [
        `${outerKey}.${innerKey}`,
        value,
      ]),
    ),
  );
};
