import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useServices } from '../../common/providers/service-provider';
import { PrimaryButton } from '../../common/ui/buttons';
import { ServiceWorkerWrapper } from '../../services/service-worker';

export const AppUpdateDialog = () => {
  const { t } = useTranslation();

  const { serviceWorker } = useServices();
  const {
    isOpen,
    open,
    close,
    params: serviceWorkerWrapper,
  } = useDialog<ServiceWorkerWrapper, void>();

  React.useEffect(() => {
    return serviceWorker.registerListener((wrapper) => open(wrapper));
  }, [serviceWorker, open]);

  return (
    <Dialog open={isOpen} onClose={() => close()}>
      <DialogTitle>{t('app_update_dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('app_update_dialog.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          variant="outlined"
          color={'secondary'}
          onClick={() => close()}
        >
          {t('app_update_dialog.btn_later')}
        </PrimaryButton>
        <PrimaryButton
          variant="contained"
          onClick={() => serviceWorkerWrapper?.update()}
        >
          {t('app_update_dialog.btn_refresh')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
