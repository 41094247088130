import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import { Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { StyledDataGrid } from '../../common/styles';
import {
  EvaluationFormResult,
  EvaluationFormResultStatus,
  EvaluationFormViewMode,
} from '../../common/types';
import { PrimaryButton } from '../../common/ui/buttons';
import { StyledFormContainer } from '../../common/ui/containers';
import { EvaluationFormView } from '../../common/ui/evaluation-form';
import { useEvaluationForm } from '../home/hooks';
import { StyledDialog } from '../page-styles';
import { AssignedEvaluationFormResultsDataGridLegend } from './AssignedEvaluationFormResultsDataGridLegend';
import { CircleIcon } from './CircleIcon';
import { useFetchEvaluationFormResults } from './useFetchEvaluationFormResults';

export const AssignedEvaluationFormResultsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: evaluationFormResults, isLoading } =
    useFetchEvaluationFormResults();
  const { isOpen, close, open } = useDialog<void, void>();
  const { data: evaluationForm, mutate: fetchEvaluationForm } =
    useEvaluationForm(open);

  const columns: GridColDef<EvaluationFormResult>[] = [
    {
      field: 'name',
      headerName: t('page.assigned_evaluation_form_results_page.table.name'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: t('page.assigned_evaluation_form_results_page.table.status'),
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => {
        switch (params.row.status) {
          case EvaluationFormResultStatus.inReview:
          case EvaluationFormResultStatus.waitingForReview:
            return (
              <PrimaryButton
                size={'small'}
                pill
                bold
                onClick={async () => {
                  fetchEvaluationForm(params.row.id);
                }}
              >
                {t(
                  `${
                    params.row.status === EvaluationFormResultStatus.inReview
                      ? 'page.assigned_evaluation_form_results_page.table.review_button.edit'
                      : 'page.assigned_evaluation_form_results_page.table.review_button.review'
                  }`,
                )}
              </PrimaryButton>
            );
          case EvaluationFormResultStatus.open:
            return <CircleIcon color={theme.palette.warning.main} />;
          case EvaluationFormResultStatus.completed:
            return <CircleIcon color={theme.palette.success.main} />;
        }
      },
    },
  ];
  return (
    <>
      <Typography variant={'h1'}>
        {t('page.assigned_evaluation_form_results_page.headline')}
      </Typography>
      <StyledFormContainer>
        <StyledDataGrid
          sortingMode={'client'}
          disableColumnFilter
          autoHeight
          disableRowSelectionOnClick
          rows={evaluationFormResults ?? []}
          hideFooterPagination={true}
          pageSizeOptions={[]}
          loading={isLoading}
          columns={columns}
        />
      </StyledFormContainer>
      <AssignedEvaluationFormResultsDataGridLegend />

      {evaluationForm && (
        <StyledDialog isOpen={isOpen} onClose={close}>
          <EvaluationFormView
            mode={EvaluationFormViewMode.review}
            evaluationForm={evaluationForm}
            closeDialog={close}
          />
        </StyledDialog>
      )}
    </>
  );
};
