import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDialog } from '@lumind-solutions/react-hooks/use-dialog';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { IconButton, Tooltip } from '@mui/material';

import { User } from '../../../common/types';
import { EditUserFormValues } from '../types';
import { EditUserDialog } from './EditUserDialog';

interface Props {
  currentUser: User;
}

// This component is memoized, because it's used in a MUI data grid cell,
// which has an unexpected rerendering behaviour
export const EditUserButton = React.memo(({ currentUser }: Props) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useDialog<void, void>();

  return (
    <>
      <Tooltip title={t('page.add_user.dialog_edit.tooltip')}>
        <IconButton onClick={() => open()}>
          <ModeEditOutlineIcon />
        </IconButton>
      </Tooltip>

      <EditUserDialog
        isDialogOpen={isOpen}
        onClose={close}
        userToEdit={convertToEditUserFormValues(currentUser)}
      />
    </>
  );
});

const convertToEditUserFormValues = (user: User): EditUserFormValues => {
  return {
    store: user.store,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.role,
    id: user.id,
    managerId: user.managerId,
  };
};
