import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem, TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)(() => ({
  fontSize: '1rem',
  minWidth: 150,

  '& .input, & label': {
    fontSize: '1rem',
  },
})) as typeof TextField;

interface Props {
  id: string;
  label: string;
  value: string | null;
  onChange: (newValue: string | null) => void;
  options: { label: string; value: string }[];
}

export const SelectFilter = ({
  id,
  label,
  value,
  onChange,
  options,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledTextField
      select={true}
      InputProps={{
        className: 'input',
      }}
      id={id}
      size={'small'}
      value={value !== null ? value : ''}
      label={label}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedItem = event.target.value;
        onChange(selectedItem === '' ? null : selectedItem);
      }}
    >
      <MenuItem key={'empty'} value={''}>
        {t('common.drop_down.empty_field')}
      </MenuItem>
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};
