import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';

export const useDeleteUser = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { userService } = useServices();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  return useMutation<void, AppError, string>(
    async (id) => {
      await userService.deleteUser(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        onSuccess?.();
      },
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
};
