import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../common/providers/service-provider';
import { FilterOption } from '../../common/types';
import { useSnackbar } from '../../common/ui/snackbar';
import { AppError } from '../../services/errors';
import { createErrorMessage } from '../../utils/errors/create-error-message';

export const useFetchFilterOptions = () => {
  const { rankingService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { data: filterOptions, isLoading } = useQuery<FilterOption[], AppError>(
    ['filter-option'],
    async () => {
      return rankingService.fetchFilterOptions();
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
  return { filterOptions, isLoading } as const;
};
