export interface ServiceWorkerWrapper {
  update: () => Promise<void>;
}

export class ServiceWorkerWrapperImpl implements ServiceWorkerWrapper {
  constructor(
    private readonly updateFn: (reloadPage?: boolean) => Promise<void>,
  ) {}

  async update() {
    return this.updateFn(true);
  }
}
