import React from 'react';
import { useTranslation } from 'react-i18next';

import { RankingPeriod } from '../../../common/types';
import { BaseButton } from '../../../common/ui/buttons/BaseButton';

interface Props {
  onFilterChange: (period: RankingPeriod) => void;
  selectedPeriodType: RankingPeriod;
}

export const PeriodFilter = ({ onFilterChange, selectedPeriodType }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <BaseButton
        variant={
          selectedPeriodType === 'QUARTER_OR_MONTH' ? 'contained' : 'outlined'
        }
        onClick={() => {
          onFilterChange('QUARTER_OR_MONTH');
        }}
        size={'small'}
        disableElevation
      >
        {t('page.ranking.filter_buttons.month')}
      </BaseButton>
      <BaseButton
        variant={selectedPeriodType === 'YEAR' ? 'contained' : 'outlined'}
        onClick={() => {
          onFilterChange('YEAR');
        }}
        size={'small'}
        disableElevation
      >
        {t('page.ranking.filter_buttons.year')}
      </BaseButton>
    </>
  );
};
