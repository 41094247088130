import React from 'react';

import { createUseContextHook } from '@lumind-solutions/react-hooks/create-use-context-hook';

import { AuthService } from '../../../services/auth';
import { EvaluationFormResultService } from '../../../services/evaluation-form';
import { HttpClient } from '../../../services/http-client';
import { RankingService } from '../../../services/ranking';
import { ServiceWorkerService } from '../../../services/service-worker';
import { UserService } from '../../../services/user';

export interface Services {
  authService: AuthService;
  userService: UserService;
  rankingService: RankingService;
  evaluationFormResultService: EvaluationFormResultService;
  authorizedHttpClient: HttpClient;
  serviceWorker: ServiceWorkerService;
}

interface Props {
  children: React.ReactNode;
  services: Services;
}

const ServiceContext = React.createContext<Services | null>(null);
ServiceContext.displayName = 'ServiceContext';

export const ServiceProvider = ({ children, services }: Props) => {
  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useServices = createUseContextHook(ServiceContext);
