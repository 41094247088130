import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import { useUser } from '../common/hooks/use-user';
import { Layout } from '../common/layout';
import { useServices } from '../common/providers/service-provider';
import { AssignedEvaluationFormResultsPage } from '../pages/assigned-evaluation-form-results/AssignedEvaluationFormResultsPage';
import { ForgotPasswordPage } from '../pages/auth-flow/forgot-password';
import { LoginPage } from '../pages/auth-flow/login';
import { RegisterPage } from '../pages/auth-flow/register';
import { HomePage } from '../pages/home';
import { ManageUserPage } from '../pages/manage-user';
import { RankingsPage } from '../pages/ranking';
import {
  ASSIGNED_EVALUATION_FORM_RESULTS,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  MANAGE_USER,
  RANKING,
  REGISTER,
} from './routes';

function RequireAuth() {
  const { authService } = useServices();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    return authService.onUserChanged((user) => {
      setIsLoggedIn(!!user);
    });
  }, [authService]);

  if (isLoggedIn === null) {
    return null;
  } else if (isLoggedIn) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else {
    return <Navigate to={LOGIN} replace />;
  }
}

export const AppRouter = () => {
  const user = useUser();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={REGISTER} element={<RegisterPage />} />
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />

        <Route path={'/'} element={<RequireAuth />}>
          <Route path={HOME} element={<HomePage />} />
          {user?.ability.can('manage', 'User') && (
            <Route path={MANAGE_USER} element={<ManageUserPage />} />
          )}
          {user?.ability.can('update', 'EvaluationFormResult') && (
            <Route
              path={ASSIGNED_EVALUATION_FORM_RESULTS}
              element={<AssignedEvaluationFormResultsPage />}
            />
          )}

          {user?.ability.can('read', 'Ranking') && (
            <Route path={RANKING} element={<RankingsPage />} />
          )}
          <Route path={'*'} element={<div>{'Page not found'}</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
