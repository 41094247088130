import { useMutation } from '@tanstack/react-query';

import { AppError } from '../../../services/errors';
import { useServices } from '../../providers/service-provider';

export const useLogout = () => {
  const { authService } = useServices();

  return useMutation<void, AppError, void>(() => authService.logout());
};
