import React from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import { SelectFilter } from '../../../common/ui/filter';
import { useFetchFilterOptions } from '../useFetchFilterOptions';

interface Props {
  onFilterChange: (questionName: string | null) => void;
  selectedQuestion: string | null;
}

export const QuestionFilter = ({ onFilterChange, selectedQuestion }: Props) => {
  const { t } = useTranslation();

  const { filterOptions, isLoading } = useFetchFilterOptions();

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <SelectFilter
          id={'question'}
          label={t('page.ranking.filter_buttons.question')}
          value={selectedQuestion}
          onChange={onFilterChange}
          options={
            filterOptions?.map((it) => ({
              label: it.title,
              value: it.name,
            })) ?? []
          }
        />
      )}
    </>
  );
};
