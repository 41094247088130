import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { useServices } from '../../../common/providers/service-provider';
import { EvaluationFormSummary } from '../../../common/types';
import { useSnackbar } from '../../../common/ui/snackbar';
import { AppError } from '../../../services/errors';
import { createErrorMessage } from '../../../utils/errors/create-error-message';

export const useEvaluationFormSummary = () => {
  const { evaluationFormResultService } = useServices();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    data: evaluationFormSummary,
    error,
    isLoading,
  } = useQuery<EvaluationFormSummary, AppError>(
    ['evaluation-form-result-summary'],
    async () => {
      return await evaluationFormResultService.fetchCurrentEvaluationFormResultSummary();
    },
    {
      onError: (error) => {
        showSnackbar({
          variant: 'error',
          content: createErrorMessage(t, error),
        });
      },
    },
  );
  return { evaluationFormSummary, error, isLoading };
};
