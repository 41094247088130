import { merge } from 'lodash';

import { Answers } from '../../../../types';

export const convertSurveyResultToAnswers = (
  modelData: Record<string, number>,
): Answers => {
  const convertedObject = {};
  Object.entries(modelData).forEach((entry) => {
    const [key, value] = entry;
    const convertedProperty = key
      .split('.')
      .reverse()
      .reduce((res, key) => ({ [key]: res }), value as any);
    merge(convertedObject, convertedProperty);
  });
  return convertedObject;
};
