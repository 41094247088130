import * as React from 'react';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { stores } from '@rewe/common';

import { PrimaryButton } from '../../../common/ui/buttons';
import { Dialog } from '../../../common/ui/dialog';
import { UpsertUserForm } from '../UpsertUserForm';
import { EditUserFormValues, UpsertUserFormValues } from '../types';
import { useEditUser } from './useEditUser';

interface Props {
  isDialogOpen: boolean;
  onClose: () => void;
  userToEdit: EditUserFormValues;
}

export const EditUserDialog = ({
  isDialogOpen,
  onClose,
  userToEdit,
}: Props) => {
  const {
    mutate: editUser,
    error: editUserError,
    isLoading: isLoadingEditUser,
  } = useEditUser(userToEdit.id, onClose);

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    resetField,
    reset,
    watch,
    formState: { errors: formErrors },
  } = useForm<UpsertUserFormValues>({});

  /*get all data to edit*/
  useEffect(() => {
    reset({ ...userToEdit, managerId: userToEdit.managerId ?? '' });
  }, [reset, userToEdit]);

  const onSubmit: SubmitHandler<UpsertUserFormValues> = (data) => {
    editUser(data);
  };
  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={onClose}
      title={t('page.add_user.dialog_edit.title')}
      contentPadding={3}
      actions={() => {
        return (
          <>
            <PrimaryButton
              variant="outlined"
              color={'secondary'}
              onClick={onClose}
            >
              {t('page.add_user.dialog_edit.cancel_button')}
            </PrimaryButton>
            <PrimaryButton
              loading={isLoadingEditUser}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t('page.add_user.dialog_edit.submit_button')}
            </PrimaryButton>
          </>
        );
      }}
    >
      <UpsertUserForm
        userId={userToEdit.id}
        control={control}
        formErrors={formErrors}
        addUserError={editUserError}
        store={watch('store', stores[0].id)}
        resetField={resetField}
      />
    </Dialog>
  );
};
